@import 'modules/header/header';
@import 'modules/footer2/footer2';
@import 'modules/analize/analize';
@import 'modules/banner/banner';
@import 'modules/banner2/banner2';
@import 'modules/banners-swiper/banners-swiper';
@import 'modules/services-tabs/services-tabs';
@import 'modules/info-block/info-block';
@import 'modules/features/features.scss';
@import 'modules/offers/offers.scss';
@import 'modules/video-swiper/video-swiper';
@import 'modules/reviews-swiper/reviews-swiper';
@import 'modules/doctors-swiper/doctors-swiper';
@import 'modules/news-n-articles/news-n-articles';
@import 'modules/partners/partners.scss';
@import 'modules/popular-services/popular-services.scss';
@import 'modules/breadcrumbs/breadcrumbs';
@import 'modules/page-elements/page-elements';
@import 'modules/what-we-treat/what-we-treat';
@import 'modules/why-kim/why-kim';
@import 'modules/personal-services/personal-services';
@import 'modules/price-block/price-block';
@import 'modules/banner-form/banner-form';
@import 'modules/doctors-block/doctors-block';
@import 'modules/doctor-block/doctor-block';
@import 'modules/reviews-block/reviews-block';
@import 'modules/articles/articles';
@import 'modules/popup-zapis/popup-zapis';
/* @import 'modules'; */
