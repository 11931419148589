.page__wrapper {
    --bs-gutter-y: 0;
    --bs-gutter-x: 0;
    width: 100%;
    height: inherit;
    margin-right: auto;
    margin-left: auto;
    max-width: 1320px;
    padding-right: 1.5rem;
    padding-left: 1.5rem;
    font-family: 'Open Sans', sans-serif;
    @include media-breakpoint-between(xl, xxl) {
        max-width: 1140px;
    }
    @include media-breakpoint-up(xl) {
        padding-right: 0;
        padding-left: 0;
    }
    @include media-breakpoint-up(lg) {
        font-size: 1rem;
        margin-bottom: 1rem;
    }
    @include media-breakpoint-down(lg) {
        font-size: 0.875rem;
        margin-bottom: 0.75rem;
    }
    & h1 {
        font-weight: 600;
        color: $gray-900;
        margin: 2rem 0 1.5rem;
        @include font-size(2.5rem);
        @include media-breakpoint-down(xl) {
            @include font-size(2rem);
        }
        @include media-breakpoint-down(lg) {
            @include font-size(1.5rem);
        }
        @include media-breakpoint-down(md) {
            //font-size: 1.125rem;
        }
        @include media-breakpoint-down(sm) {
        }
    }
    & h2 {
        @include font-size(2rem);
        @include media-breakpoint-down(xl) {
            @include font-size(1.5rem);
        }
        @include media-breakpoint-down(lg) {
            @include font-size(1.25rem);
        }
        @include media-breakpoint-down(md) {
            @include font-size(1.125rem);
        }
        margin: 2rem auto;
        font-weight: 600;
    }
    & .page__content-links {
        display: flex;
        flex-wrap: wrap;
        align-items: stretch;
        width: 100%;
        height: auto;
        gap: 0.5rem;
        @include media-breakpoint-down(lg) {
            gap: 0.25rem;
        }
        & > a {
            @include font-size(1rem);
            &.btn-light-green {
                @include button-variant(#eff8f3, #eff8f3, $gray-900, #009846, #009846, #fff, #009846, #009846, #fff, $gray-300, $gray-300, $gray-900);
            }
            display: block;
            width: max-content;
            flex: 0 0 auto;
            @include media-breakpoint-down(lg) {
                @include font-size(0.875rem);
            }
        }
    }
    p {
        color: $gray-900;
        font-weight: 400;
        line-height: 150%;
    }
    ul,
    .markers {
        margin: 1.125rem 0;
        padding: 0;
        list-style-type: none;
        &.no-markers li {
            &::before {
                content: '' !important;
                @include visually-hidden();
            }
        }
    }
    ol li {
        font-size: inherit;
        margin: 0.75rem 0;
    }
    ul li,
    .markers li {
        font-size: inherit;
        margin: 0.75rem 0;
        &::before {
            display: inline-block;
            width: 16px;
            height: 16px;
            vertical-align: sub;
            margin-right: 1rem;
            content: url('/assets/img/sprites/list-play16.svg');
        }
    }
    ul li::marker,
    .markers li::marker {
        color: transparent;
        margin-top: 10px;
        font-size: inherit;
        line-height: 150%;
    }
    img {
        max-width: 100%;
        object-fit: contain;
        border-radius: $border-radius;
    }
    .first-image {
        width: 310px;
        height: 360px;
        margin: 1rem 4rem 3rem 0;
        & > img {
            object-fit: cover;
            object-position: top center;
            width: 100%;
            height: 100%;
        }
        @include media-breakpoint-down(xl) {
            height: 320px !important;
            margin: 1.5rem 4rem 1.5rem 0;
        }
        @include media-breakpoint-down(md) {
            width: 100%;
            margin: 1.5rem 0;
        }
        @include media-breakpoint-down(sm) {
            height: 205px;
        }
    }
    img[class^='image-'],
    picture[class^='image-'] {
        display: block;
        overflow: hidden;
        border-radius: 10px;
        margin: 50px;
        min-width: 40%;
    }
    picture[class^='image-'] img {
        width: 100;
        object-fit: contain;
    }
    .image-left {
        float: left;
        margin-left: 0;
    }
    .image-right {
        float: right;
        margin-right: 0;
    }
    .image-center {
        width: 100%;
        height: auto;
        clear: both;
    }
    .note__block {
        width: 100%;
        display: inline-block;
        position: relative;
        border-radius: 7px;
        background: #eff8f3;
        padding: rfs-fluid-value(1rem) rfs-fluid-value(2rem);
        padding-left: 50px;
        margin-bottom: 0.75rem;
        color: $gray-900;
        font-size: inherit;
        /* @include font-size(1rem);
        @include media-breakpoint-down(lg) {
            @include font-size(0.875rem);
        } */
        font-style: normal;
        font-weight: 600;
        line-height: 150%;
        &::before {
            content: '';
            width: 24px;
            height: 24px;
            background-image: url('/assets/img/sprites/info.svg');
            background-size: contain;
            background-position: center center;
            position: absolute;
            top: 15px;
            left: 15px;
        }
    }
    blockquote {
        display: block;
        padding: 10px 50px;
        position: relative;
        font-size: inherit;
        color: #025626;
        font-weight: 600;
        line-height: 150%;
        font-style: italic;
        margin-bottom: 0.75rem;
        & * {
            color: inherit;
            font-weight: inherit;
            line-height: inherit;
        }

        &::before,
        &::after {
            content: '';
            width: 24px;
            height: 24px;
            background-size: contain;
            background-position: center center;
            position: absolute;
        }
        &::before {
            background-image: url('/assets/img/sprites/quote-open.svg');
            top: 0;
            left: 0;
        }
        &::after {
            background-image: url('/assets/img/sprites/quote-close.svg');
            bottom: 0;
            right: 0;
        }
    }
}
