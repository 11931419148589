.doctors {
    background-color: white;
    padding: 22px auto;
    padding-top: 2.5rem;
    & .doctors-slider__slide {
        width: max-content;
        height: auto;
        align-self: stretch;
        overflow: visible;
        display: flex;
        justify-content: center;
        &.card > .card-img-top {
            height: 270px;
            &:hover {
                cursor: pointer;
            }
        }
    }
    & .doctors__block-wrapper {
        width: 100%;
        height: auto;
        position: relative;
    }
    & .doctors-slider {
        & .swiper-wrapper {
            overflow-x: clip;
            overflow-clip-margin: 20px;
            align-items: stretch;
            margin-bottom: 2rem;
        }
    }
}
.swiper-wrapper {
    @include media-breakpoint-down(xs) {
        & .card.doctor-card {
            width: 100%;
            max-width: 240px;
        }
    }
}
.card.doctor-card {
    overflow: hidden;
    border-radius: 7px;
    border: 1px solid $gray-100;
    background: #fff;
    position: relative;
    &:hover {
        box-shadow: 0px 0px 25px 0px rgba(169, 169, 169, 0.6);
    }
    flex-basis: 280px;
    height: 450px;
    min-width: 250px;
    max-width: 290px;

    & > img {
        flex-shrink: 0;
        height: 100%;
        width: 100%;
        object-fit: cover;
        object-position: top center;
    }
    & .card-body {
        flex-grow: 1;
        flex-shrink: 0;
        font-size: 1em;
        font-weight: 600;
        & .doctor-name {
            font-size: 1rem;
            font-weight: 600;
            line-height: 110%;
            color: $gray-900;
            margin-bottom: 1em;
        }
        & .doctor-specialties {
            font-size: 0.75em;
            line-height: 120%;
            font-weight: 400;
            color: $green;
            margin-bottom: 0;
        }
        & .btn {
            --bs-btn-padding-y: 6px;
            --bs-btn-padding-x: 28px;
        }
        padding: 8px 24px 16px 24px;
        text-overflow: ellipsis;
        line-height: 150%;
    }
    & .card-icons {
        position: absolute;
        right: 20px;
        top: 8px;
        width: 25px;
        //height: 250px;
        background-color: transparent;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-end;
        gap: 10px;
        & .reviews-about {
            padding: 2px 8px;
            border-radius: 5px;
            background: #fff;
            color: $gray-900;
            font-size: 0.75em;
            font-weight: 400;
            white-space: nowrap;
            &:hover {
                color: $blue;
                //font-weight: 700;
            }
        }
        & svg {
            width: 24px;
            height: 24px;
            object-fit: contain;
            object-position: top center;
        }
    }
    & .card-footer {
        text-align: center;
        --bs-card-cap-bg: white;
        padding: 0 16px 14px 16px;
        border-top: none;
        & .btn {
            --bs-btn-padding-y: 6px;
            --bs-btn-padding-x: 28px;
        }
    }
}
