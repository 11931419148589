.link__item.icon-link {
    --bs-btn-padding-y: 1rem;
    --bs-btn-padding-x: 0.5rem;
    --bs-btn-border-radius: 8px;
    --bs-btn-font-weight: 600;
    --bs-btn-line-height: 1.2;
    flex-grow: 1;
    flex-basis: 0;
    min-width: 0;
    overflow: hidden;
    align-items: center;
    justify-content: start;
    text-align: start;
    & > img,
    & > svg {
        display: block;
        width: 50px;
        height: 50px;
        margin-right: 0.5rem;
    }
}
.popular-tests {
    & .card {
        background-color: rgba(35, 35, 35, 0.03) !important;
        --bs-card-color: rgba(35, 35, 35, 0.03) !important;
        border: 0;
        & .card-header {
            background-color: transparent;
            --bs-card-cap-color: transparent;
            font-weight: 600;
            text-align: justify;
        }
    }
}
.analyzes-types {
    background-color: #f6f6f6;
    padding: 1rem;
    border-radius: 7px;
    & .analyzes-types__wrapper {
        & > ul {
            columns: 3;
            @include media-breakpoint-down(lg) {
                columns: 2 auto;
            }
            @include media-breakpoint-down(sm) {
                columns: 1 auto;
            }
            & li > a {
                --bs-text-opacity: 1;
                color: rgba(var(--bs-secondary-rgb), var(--bs-text-opacity)) !important;
            }
            & li:hover > a {
                font-weight: bolder;
                color: rgba($green, var(--bs-text-opacity)) !important;
            }
        }
    }
}
