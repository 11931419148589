@import '../../node_modules/swiper/swiper.scss';
@import '../../node_modules/swiper/modules/a11y.scss';
@import '../../node_modules/swiper/modules/autoplay.scss';

//@import '../../node_modules/swiper/modules/navigation.scss';
//@import '../../node_modules/swiper/modules/pagination.scss';

$slider-controls-bottom-margin: 30px !default;
@at-root {
    :root {
        --swiper-theme-color: #{$green};

        --swiper-preloader-color: var(--swiper-theme-color);
        /*--swiper-wrapper-transition-timing-function: initial;
      */
    }
}
.swiper {
    z-index: unset;
    overflow: clip;
    overflow-clip-margin: 10px;
    &.swiper-wrapper {
        overflow: clip;
        overflow-clip-margin: 10px;
    }
}
.swiper div[class$='__controls'],
.swiper-controls {
    width: 100%;
    @include media-breakpoint-down(xl) {
        height: 26px;
    }
    @include media-breakpoint-up(xl) {
        height: 40px;
    }
    &:has(.slider__arrow-prev.swiper-button-disabled):has(.slider__arrow-next.swiper-button-disabled):has(.swiper-pagination-lock.slider__dots) {
        @include visually-hidden();
    }
    display: inline-flex;
    flex-wrap: nowrap;
    column-gap: 0.5rem;
    justify-content: center;
    align-items: center;
}
.swiper .slider__arrows {
    cursor: pointer;
    position: relative;
    @include media-breakpoint-down(xl) {
        width: 24px;
        height: 24px;
    }
    @include media-breakpoint-up(xl) {
        width: 40px;
        height: 40px;
    }
    &::before,
    &::after {
        content: '';
        display: block;
        background-color: transparent;
        position: absolute;
        top: 50%;
        left: 50%;
        transition: background-color 0.3s ease 0s;
    }
    &::before {
        border: 2px solid $green;
        border-radius: 50%;
        @include media-breakpoint-down(lg) {
            width: 20px;
            height: 20px;
            margin-top: -10px;
            margin-left: -10px;
        }
        @include media-breakpoint-down(xl) {
            width: 22px;
            height: 22px;
            margin-top: -11px;
            margin-left: -11px;
        }
        @include media-breakpoint-up(xl) {
            width: 33px;
            height: 33px;
            margin-top: -16.5px;
            margin-left: -16.5px;
        }
    }
    &::after {
        border-top: 3px solid $green;
        border-left: 3px solid $green;
        border-bottom: none;
        border-right: none;
        background-color: transparent;
        @include media-breakpoint-down(xl) {
            width: 8px;
            height: 8px;
            margin-top: -4px;
        }
        @include media-breakpoint-up(xl) {
            width: 12px;
            height: 12px;
            margin-top: -6px;
        }
    }
    &:hover {
        &::before {
            background-color: $green;
        }
        &::after {
            border-color: white;
        }
    }
    &.slider__arrow-prev {
        &::after {
            transform: rotate(-46deg);
            @include media-breakpoint-down(xl) {
                margin-left: -3px;
            }
            @include media-breakpoint-up(xl) {
                margin-left: -4px;
            }
        }
    }
    &.slider__arrow-next {
        &::after {
            transform: rotate(134deg);
            @include media-breakpoint-down(xl) {
                margin-left: -5px;
            }
            @include media-breakpoint-up(xl) {
                margin-left: -8px;
            }
        }
    }
}

.swiper .slider__dots {
    --swiper-pagination-bullet-size: 8px;
    --swiper-pagination-bullet-width: 8px;
    --swiper-pagination-bullet-height: 8px;
    --swiper-pagination-bullet-inactive-color: #fff;
    --swiper-pagination-bullet-horizontal-gap: 4px;
    @include media-breakpoint-up(xl) {
        height: 8px;
    }
    @include media-breakpoint-down(xl) {
        height: 6px;
    }
    overflow-y: visible;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: center;
    &.dark .slider__dot:not(.slider__dot-active) {
        background-color: #f0f0f0;
    }
    &.dark72 .slider__dot:not(.slider__dot-active) {
        background-color: #727272;
    }
}
.swiper .slider__dot {
    cursor: pointer;
    display: block;
    width: 6px;
    height: 6px;
    border-radius: 3px;
    @include media-breakpoint-up(xl) {
        width: 8px;
        height: 8px;
        border-radius: 4px;
    }
    //border: 1px solid $green;

    border: 1px solid transparent; //$green;
    background-color: #fff;
    transition:
        background-color,
        scale 0.3s ease 0s;
    &:not(:last-child) {
        margin-right: 4px;
    }
    &.slider__dot-active {
        transform: scale(1.25, 1.25);
        pointer-events: none;
        background-color: $green;
    }
}
.swiper-button-disabled,
.swiper-pagination-lock {
    visibility: hidden !important;
}
.swiper-pagination-bullet {
    width: 8px;
    height: 8px;
}
.swiper-pagination-bullet:hover {
    width: 10px;
    height: 10px;
}
.swiper-pagination-bullet-light {
    background: #f0f0f0;
}
.swiper-pagination-bullet-dark {
    background: #fff;
}
.swiper-pagination-bullet-active {
    background: #009846;
}
