.video__block {
    font-size: 1rem;
    background-color: white;
    padding: 22px auto;
    & .video__block-caption {
        display: flex;
        flex-wrap: nowrap;
        justify-content: space-between;
        & span {
            font-size: 2em;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
            @include media-breakpoint-down(xl) {
                font-size: 1.5em;
            }
            @include media-breakpoint-down(md) {
                font-size: 1.125em;
            }
        }
        & .btn {
            //--bs-btn-padding-x: 1em;
            //--bs-btn-padding-y: 6px;
        }
    }
    & .video-slider__slide {
        height: auto; //200px;
        @include media-breakpoint-down(xl) {
            //height: 168px;
        }
    }
    & .video__block-wrapper {
        width: 100%;
        height: auto;
    }
    & .video-slider {
        width: 100%;
        & .swiper-wrapper {
            align-items: stretch;
            @include media-breakpoint-up(xl) {
                margin-bottom: 2rem;
            }
        }
    }
    & .video-item {
        position: relative;
        border-radius: 7px;
        overflow: hidden;
        //max-width: 380px;
        //flex: 1 0 380px;
        & .video-link > img:first-child {
            height: 100%;
            width: 100%;
            object-fit: cover;
            object-position: top center;
            flex: 0 0 200px;
            @include media-breakpoint-down(xl) {
                flex: 0 0 168px;
            }
            border-radius: 7px;
            overflow: hidden;
        }
        & .video-link > span {
            font-size: 1em;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
            padding: 0.5em;
            @include media-breakpoint-down(xl) {
                padding: 1em 0.5em;
            }
            text-align: center;
        }
        & .video-link {
            display: flex;
            flex-direction: column;
            align-items: stretch;
            justify-content: flex-start;
            position: relative;
            width: 100%;
            height: 100%;
            &::before,
            &::after {
                content: '';
                display: block;
                position: absolute;
                -webkit-transform: translate(-50%, -50%);
                -ms-transform: translate(-50%, -50%);
                transform: translate(-50%, -50%);
                top: 100px;
                @include media-breakpoint-down(xl) {
                    top: 84px;
                }
                left: 50%;
                transition: scale 0.3s ease 0s;
            }
            &::before {
                width: 40px;
                height: 40px;
                border-radius: 20px;
                border: 3px solid white;
                background-color: transparent;
            }
            &::after {
                content: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='40' height='40' viewBox='0 0 40 40' fill='none'%3E%3Cg clip-path='url(%23clip0_114_659)'%3E%3Cpath d='M27.6298 18.5842L15.5849 11.5847C14.269 10.8059 13.0078 11.4333 13.0078 12.9788V27.023C13.0078 28.566 14.269 29.1946 15.5849 28.4145L27.5346 21.4163C28.8553 20.6374 28.9494 19.3631 27.6298 18.5842Z' fill='white'/%3E%3C/g%3E%3Cdefs%3E%3CclipPath id='clip0_114_659'%3E%3Crect width='40' height='40' fill='white'/%3E%3C/clipPath%3E%3C/defs%3E%3C/svg%3E");
                width: 40px;
                height: 40px;
                //content: '';
                /* width: 0;
                height: 0;
                border-top: 8px solid transparent;
                border-left: 15px solid white;
                border-bottom: 8px solid transparent; */
            }
            &:hover {
                &::before {
                    background-color: $green;
                    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.6);
                }
                &::after,
                &::before {
                    -webkit-transform: translate(-50%, -50%) scale(1.25, 1.25);
                    -ms-transform: translate(-50%, -50%) scale(1.25, 1.25);
                    transform: translate(-50%, -50%) scale(1.25, 1.25);
                }
            }
        }

        width: 100%;
        @include media-breakpoint-up(xl) {
            //width: 33.3%;
            border-radius: 5px;
        }
        @include media-breakpoint-down(xl) {
            //width: 33.3%;
            flex: 1 0 280px;
        }
        @include media-breakpoint-down(lg) {
            //width: 50%;
        }
        @include media-breakpoint-down(md) {
        }
        @include media-breakpoint-down(sm) {
        }
        @include media-breakpoint-between(sm, md) {
            //width: 350px;
        }
    }
}
