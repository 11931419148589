.footer2 {
    background-color: rgba(119, 148, 199, 1);
    & .footer-upper {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        grid-template-rows: repeat(4, auto);
        grid-template-areas:
            'logo socials map'
            'buttons phone map'
            'buttons menu map'
            'info menu map';
        grid-auto-columns: 1fr;
        grid-auto-rows: auto-fit;
        grid-auto-flow: column;
        justify-content: stretch;
        align-items: start;
        row-gap: 1.5rem;
        column-gap: 4rem;
    }
    & .footer-logo {
        grid-area: logo;
        width: 100%;
        & > a {
            display: block;
            height: inherit;
        }
        & .logo-svg {
            width: 100%;
            object-fit: contain;
            object-position: left top;
        }
    }
    & .footer-buttons {
        grid-area: buttons;
        font-size: 0.875em;
        & > a {
            --bs-btn-padding-y: 0.875rem;
        }
    }
    & .footer-info {
        display: block;
        grid-area: info;
        & .icon-link {
            fill: white;
            color: white;
            font-weight: 600;
            font-size: 13px;
            line-height: 18px;
            & svg {
                width: 15px;
                height: 15px;
                object-fit: contain;
            }
        }
    }
    & .footer-socials {
        grid-area: socials;
        display: inline-flex;
        column-gap: 1.5rem;
        row-gap: 2rem;
        & > a > svg {
            width: 32px;
            height: 32px;
            object-fit: contain;
        }
    }
    & .footer-phone {
        grid-area: phone;
        color: white;
        & > div.phone-wrap {
            display: flex;
            width: fit-content;
            flex-direction: column;
        }
        & a:hover,
        a:focus {
            text-decoration: underline;
        }
        & .phone-text {
            display: block;
            text-align: center;
            color: inherit;
            font-size: 0.75em;
            font-weight: 600;
            & > svg {
                fill: white;
                width: 20px;
                height: 20px;
                object-fit: contain;
            }
        }
        & .phone-number {
            display: block;
            --bs-nav-link-padding-y: 0;
            --bs-nav-link-padding-x: 0;
            --bs-nav-link-font-size: 1.5rem;
            --bs-nav-link-font-weight: 600;
            --bs-nav-link-color: #fff;
        }
    }
    & .footer-menu {
        grid-area: menu;
        color: white;
        column-count: 2;
        column-gap: 1.5rem;
        @include font-size(16px);
        & .nav-item {
            list-style-type: none;
            line-height: 1.5rem;
            overflow: visible;
        }
        & a:hover,
        a:focus {
            text-decoration: underline;
        }
        & .nav-link {
            --bs-nav-link-padding-y: 0.2rem;
            --bs-nav-link-padding-x: 0;
            --bs-nav-link-font-size: 1rem;
            --bs-nav-link-font-weight: 600;
            --bs-nav-link-color: #fff;
            --bs-link-underline-opacity: 0.5;
            --bs-nav-link-hover-color: #fff;
        }
    }
    & .footer-map {
        grid-area: map;
        min-height: 200px;
        width: 100%;
        height: 100%;
        background-color: #fff;
        overflow: hidden;
        border-radius: $border-radius;
    }
    @include media-breakpoint-down(xl) {
        & .footer-upper {
            column-gap: 3rem;
            grid-template-areas:
                'logo . map'
                'buttons menu map'
                'buttons menu map'
                'phone socials info';
        }
        & .footer-map {
            max-height: 250px;
        }
    }
    @include media-breakpoint-down(lg) {
        & .footer-upper {
            grid-template-columns: repeat(2, 1fr);
            grid-template-rows: repeat(5, auto);
            column-gap: 4rem;
            grid-template-areas:
                'logo map'
                'buttons map'
                'menu info'
                'phone info'
                'socials .';
        }
        & .footer-map {
            max-height: fit-content;
        }
    }
    @include media-breakpoint-down(md) {
        & .footer-upper {
            grid-template-columns: repeat(2, 1fr);
            grid-template-rows: repeat(5, auto);
            column-gap: 4rem;
            row-gap: 1.75rem;
            grid-template-areas:
                'logo socials'
                'buttons menu'
                'map map'
                'info info'
                'phone phone';
        }
        & .footer-socials {
            flex-flow: row-reverse;
        }
        & .footer-menu {
            max-width: 300px;
            justify-self: flex-end;
        }
        & .footer-map {
            //max-width: 500px;
            width: 100%;
            max-height: 250px;
        }
    }
    @include media-breakpoint-down(sm) {
        & .footer-upper {
            justify-content: center;
            grid-template-columns: 1fr;
            grid-template-rows: repeat(7, auto);
            grid-template-areas:
                'logo'
                'buttons'
                'menu'
                'map'
                'info'
                'socials'
                'phone';
            & > * {
                justify-self: center;
                max-width: 300px;
            }
        }
        & .footer-logo {
            & .logo-svg {
                height: 60px;
                object-fit: contain;
                object-position: top center;
            }
        }
        & .footer-buttons {
            & > [type='button'] {
                width: 100%;
            }
        }
    }
}
.footer-bottom {
    background-color: #f9f9f7;
    font-size: 0.75rem;
    font-weight: 300;
    color: #4d4d4d;
    &__inner {
        display: flex;
        height: 30px;
        justify-content: center;
        align-items: center;
    }
}
