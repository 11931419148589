div.pagination {
    width: 100%;
    text-align: center;
    margin-bottom: 4rem;
    @include font-size(1rem);
    font-weight: 600;
    & > ul {
        list-style-type: none;
        display: flex;
        align-items: center;
        justify-content: center;
        & > li {
            position: relative;
            display: list-item;
            margin-right: 0.75rem;
            &:not(.control-btn) {
                width: 30px;
                height: 30px;
            }
            &.active,
            &:hover:not(.control) {
                display: list-item;
                border: 1px solid $gray-100;
                border-radius: 50%;
                box-shadow: 0px 0px 5px rgba(169, 169, 169, 0.25);
                background-color: #fff;
            }
            &.disabled {
                border-color: #727272;
                & > a {
                    pointer-events: none;
                }
            }
            & a {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translateX(-50%) translateY(-50%);
                line-height: 1;
            }
            &.active > a,
            &:hover > a {
                color: $green;
            }
        }
        &:last-child {
            margin-right: 0;
        }
    }
    & .control {
        width: 40px;
        height: 40px;
        position: relative;
        font-size: 1rem;
        /* @include media-breakpoint-down(xl) {
            width: 24px;
            height: 24px;
        } */
        &:not(.disabled) {
            cursor: pointer;
            &:hover {
                & > a {
                    color: #fff;
                    font-size: inherit;
                }
                &::before {
                    background-color: $green;
                }
                &::after {
                    border-color: white;
                }
            }
        }
        &::before,
        &::after {
            content: '';
            box-sizing: border-box;
            background-color: transparent;
            position: absolute;
            top: 50%;
            left: 50%;
            transition: background-color 0.3s ease 0s;
        }
        &::before {
            display: block;
            border: 2px solid $green;
            border-radius: 50%;
            width: 33px;
            height: 33px;
            margin-top: -16.5px;
            margin-left: -16.5px;
            /* @include media-breakpoint-down(lg) {
                width: 20px;
                height: 20px;
                margin-top: -10px;
                margin-left: -10px;
            }
            @include media-breakpoint-down(xl) {
                width: 22px;
                height: 22px;
                margin-top: -11px;
                margin-left: -11px;
            }
            @include media-breakpoint-up(xl) {
                
            } */
        }
        &.control-btn::after {
            display: block;
            border-top: 3px solid $green;
            border-left: 3px solid $green;
            border-bottom: none;
            border-right: none;
            background-color: transparent;

            @include media-breakpoint-down(xl) {
                width: 8px;
                height: 8px;
                margin-top: -4px;
            }
            @include media-breakpoint-up(xl) {
                width: 12px;
                height: 12px;
                margin-top: -6px;
            }
        }
        &.disabled {
            & > a {
                pointer-events: none;
                color: #fff;
            }
            &::before,
            &::after {
                fill: $gray-400;
                background-color: $gray-400;
                border-color: $gray-400;
            }
            &::after {
                border-color: white;
            }
        }

        &.prev-btn {
            &::after {
                transform: rotate(-46deg);
                @include media-breakpoint-down(xl) {
                    margin-left: -3px;
                }
                @include media-breakpoint-up(xl) {
                    margin-left: -4px;
                }
            }
        }
        &.next-btn {
            &::after {
                transform: rotate(134deg);
                @include media-breakpoint-down(xl) {
                    margin-left: -5px;
                }
                @include media-breakpoint-up(xl) {
                    margin-left: -8px;
                }
            }
        }
    }
}
