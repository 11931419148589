.popular-services {
    &__wrapper {
        width: 100%;
    }
    &__list {
        width: 100%;
        height: min-content;
        display: grid;
        grid-template-columns: repeat(5, 1fr);
        @include media-breakpoint-down(xl) {
            grid-template-columns: repeat(4, 1fr);
        }
        @include media-breakpoint-down(lg) {
            grid-template-columns: repeat(2, 1fr);
        }
        @include media-breakpoint-down(md) {
            grid-template-columns: 1fr;
            row-gap: 0.25rem;
        }
        grid-template-rows: auto;
        justify-content: stretch;
        align-items: stretch;
        column-gap: 0.5rem;
        row-gap: 0.75rem;
    }
    & .populars__item {
        border-radius: 7px;
        border: 1px solid #f0f0f0;
        background: #fff;
        box-shadow: 0px 0px 30px 0px rgba(142, 142, 142, 0.07);
        overflow: hidden;
        grid-column: span 1;
        grid-row: span 1;
        height: auto;
        @include padding(1rem);
        //@include font-size(1rem);
        font-size: inherit;
        font-weight: 400;
        line-height: 120%;
        white-space: normal;
        word-break: break-word;
        overflow-wrap: break-word;
        & > svg,
        & > img {
            object-fit: contain;
            flex-shrink: 0;
            width: 50px;
            height: 50px;
            margin-right: 0.75rem;
            @include media-breakpoint-down(lg) {
                width: 40px;
                height: 40px;
            }
            @include media-breakpoint-down(md) {
                @include visually-hidden();
            }
        }
        &:hover {
            background: rgba(239, 248, 243, 1);
            font-weight: 700;
            color: #009846;
        }
    }
    & .icon-link {
        text-overflow: ellipsis;
    }
}
