.what-we-treat {
    background-color: $gray-200;
    padding-top: 2rem;
    padding-bottom: 3rem;
    & .alphabet__list-letter {
        color: $green;
    }
    &__list {
        &-wrapper {
            column-count: 3;
            @include media-breakpoint-down(lg) {
                column-count: 2;
            }
            @include media-breakpoint-down(md) {
                column-count: 1;
            }
        }
    }
    & .content-item {
        color: $gray-700;
        &:hover {
            color: $green;
            font-weight: 700;
            & a {
                color: inherit;
            }
        }
    }
}
