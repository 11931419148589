.features-block {
    @include font-size(1rem);
    //padding-bottom: 2.5rem;
    &__caption {
        text-align: center;
    }
    &__thumb {
        font-size: 1rem;
        position: absolute;
        top: 0;
        left: 0;
        width: 50px;
        height: 50px;
        @include media-breakpoint-down(xl) {
            width: 25px;
            height: 25px;
            top: 8px;
        }
        & > svg:first-child {
            width: 100%;
            height: 100%;
            object-fit: contain;
            object-position: top center;
        }
    }
    & .features-block__header > button {
    }
    & .accordion {
        --bs-accordion-border-width: 0;
        --bs-accordion-active-color: $gray-900;
        --bs-accordion-btn-padding-y: 6px;
        --bs-accordion-body-padding-y: 6px;
        --bs-accordion-btn-focus-border-color: transparent;
        --bs-accordion-btn-focus-box-shadow: none;
        & .accordion-item {
            $p: &;
            //items: #{$p};
            position: relative;
            margin-bottom: 20px;
            background-color: white;
            @include media-breakpoint-down(xl) {
                &.show {
                    background-color: #fffcea;
                }
            }
        }
        & .accordion-header {
            color: $gray-900;
        }
        & .accordion-button {
            @include font-size(1.125rem);
            @include media-breakpoint-down(lg) {
                @include font-size(1rem);
            }
            font-weight: 600;
            background-color: transparent;
            pointer-events: none;
            padding-left: 75px;
            @include media-breakpoint-down(xl) {
                padding-left: 40px;
            }
            &::after {
                display: none;
            }
            @include media-breakpoint-down(xl) {
                pointer-events: all;
                &::after {
                    display: block;
                }
            }
        }

        & .accordion-body {
            font-size: 1em;
            font-weight: 400;
            color: $gray-700;
            padding-left: 75px;
            line-height: 140%;
            @include media-breakpoint-down(xl) {
                padding-left: 40px;
                font-size: 0.875em;
                font-weight: 400;
            }
        }
    }
    & .features-block__desc {
        font-size: 1em;
        background: #eff8f3;
        border-radius: 8px;
        padding: 22px 40px;
        margin: 2em auto;
        & p:last-child {
            margin-bottom: 0;
        }
        @include media-breakpoint-down(xl) {
            padding: 8px 18px;
            font-size: 0.875em;
        }
        @include media-breakpoint-down(lg) {
            padding: 8px 10px;
        }
    }
}
