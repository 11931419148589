.news-n-articles__block {
    font-size: 1rem;
    background-color: #fff;
    & #news-n-articles {
        @include media-breakpoint-down(md) {
            flex-direction: column;
        }
    }
    &-wrapper {
        position: relative;
        box-shadow: unset;
        @include media-breakpoint-down(sm) {
            padding-bottom: 50px;
        }
    }
    & .tab-content {
        box-shadow: none;
    }
    & .tab-pane {
        overflow: hidden;
        &.active {
            overflow-y: visible;
        }
        & a[type='button'] {
            position: absolute;
            top: 0;
            right: 0;
            @include media-breakpoint-down(sm) {
                top: 100%;
                left: 0;
                right: 0;
                transform: translateY(-25px);
            }
        }
    }

    & .nav.nav-tabs {
        @include media-breakpoint-down(sm) {
            & > li:first-child > .nav-link {
                border-top-right-radius: $border-radius !important;
                border-top-left-radius: $border-radius !important;
                border-bottom-right-radius: 0px !important;
                border-bottom-left-radius: 0px !important;
            }
            & > li:last-child > .nav-link {
                border-top-left-radius: 0px !important;
                border-top-right-radius: 0px !important;
                border-bottom-right-radius: $border-radius !important;
                border-bottom-left-radius: $border-radius !important;
            }
        }
    }
    & .nav-link {
        border: none;
        height: 100%;
        padding: 1em;
        @include font-size(1.5rem);
        font-weight: 600;
        color: $gray-600;
        @include media-breakpoint-down(md) {
            @include font-size(1.125rem);
        }
        &.active {
            font-size: 2em;
            color: $gray-900;
            @include media-breakpoint-down(lg) {
                width: auto;
            }
        }
    }
    & .card {
        width: 100%;
        height: 100%;
        border-radius: 5px;
        display: flex;
        flex-wrap: nowrap;
        flex-direction: row;
        @include media-breakpoint-between(xl, sm) {
            flex-direction: column;
        }
        font-size: 1rem;
        gap: 20px;
        & .card-img {
            overflow: hidden;
            & > img {
                width: 100%;
                height: 100% !important;
                object-position: top center;
                object-fit: cover;
            }
        }
        border-width: 0;
        & .card-body {
            flex-grow: 1;
            flex-shrink: 1;
            padding: 0;
            color: $gray-700;
            @include font-size(0.75rem);
            text-overflow: ellipsis;
            & .card-title {
                @include font-size(1rem);
                color: $gray-900;
                font-weight: 600;
                @include margin-bottom(0.75rem);
                &:hover {
                    color: $blue;
                }
            }
            & > p,
            & .card-text {
                color: $gray-700;
                @include font-size(0.75rem);
                margin: 0;
            }
            & .date {
                display: block;
                color: $gray-600;
                font-weight: 300;
                @include font-size(0.875rem);
                @include margin-bottom(0.5rem);
            }
            @include media-breakpoint-down(lg) {
                & .card-title {
                    @include font-size(0.875rem);
                }
            }
            @include media-breakpoint-down(lg) {
            }
            @include media-breakpoint-down(md) {
                & .date {
                    @include font-size(0.75rem);
                }
            }
            @include media-breakpoint-down(sm) {
                width: 70%;
                & .card-text {
                    @include font-size(0.625rem);
                }
                & .date {
                    @include font-size(0.5rem);
                }
            }
        }
    }
    & .swiper {
        @include media-breakpoint-up(xl) {
            & .swiper-controls {
                @include visually-hidden();
            }
        }
        @include media-breakpoint-down(sm) {
            max-height: 420px;
        }
    }
    & .news__wrapper,
    & .articles__wrapper {
        width: 100%;
        display: grid;
        row-gap: 20px;
        column-gap: 20px;
        @include media-breakpoint-up(xl) {
            grid-template-columns: repeat(2, 1fr);
            grid-template-rows: repeat(2, 120px);
            grid-template-areas:
                'A B'
                'A C';
            column-gap: 25px;
            row-gap: 30px;
        }
        @include media-breakpoint-down(xl) {
            grid-template-rows: 300px;
            grid-template-columns: repeat(3, 1fr);
            grid-template-areas: 'A B C';
        }

        @include media-breakpoint-down(lg) {
            grid-template-rows: 300px;
            grid-template-columns: repeat(2, 1fr);
            grid-template-areas: 'A B';
        }
        @include media-breakpoint-down(sm) {
            grid-template-rows: repeat(3, auto);
            grid-template-columns: 100%;
            grid-template-areas:
                'A'
                'B'
                'C';
        }
    }
    & .card {
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;
        width: 100%;
        @include media-breakpoint-down(sm) {
            flex-direction: row;
        }
        @include media-breakpoint-up(xl) {
            flex-direction: row;
        }
        @include media-breakpoint-between(sm, lg) {
            &:nth-of-type(3) {
                @include visually-hidden();
            }
        }
        & > .card-img {
            overflow: hidden;
            //flex-basis: 50%;
            @include media-breakpoint-between(sm, xl) {
                width: 100%;
                height: 50%;
            }
            @include media-breakpoint-down(xl) {
                height: 200px;
            }
            @include media-breakpoint-down(md) {
                height: 145px;
            }
            @include media-breakpoint-down(sm) {
                width: 127px;
                max-height: 90px;
            }
        }
    }
    & .item {
        overflow: hidden;
    }
    & div.card:nth-of-type(1) {
        grid-area: A;
        //card-img col-3 col-md-12 col-xl-5
        @include media-breakpoint-up(xl) {
            grid-row: 1/3;
            & .card-img {
                width: 280px;
            }
        }
    }
    & div.card:nth-of-type(2) {
        grid-area: B;
        @include media-breakpoint-up(xl) {
            & .card-img {
                width: 180px;
            }
        }
    }
    & div.card:nth-of-type(3) {
        grid-area: C;
        @include media-breakpoint-up(xl) {
            & .card-img {
                width: 180px;
            }
        }
        @include media-breakpoint-between(md, lg) {
            @include visually-hidden();
        }
    }
}
