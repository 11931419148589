.offers-block {
    background-color: $gray-200;
    padding: 22px auto;
    & .offers-slider__slide {
        width: 100%;
        max-width: 280px;
        height: 360px;
        height: auto;
        align-self: stretch;
        overflow: visible;
        @include media-breakpoint-down(xs) {
            max-width: 242px;
        }
    }
    & .offers-block__wrapper {
        width: 100%;
        height: auto;
        position: relative;
    }
    & .offers-slider {
        width: 100%;
        overflow-x: clip;
        overflow-clip-margin: 10px;
        & .swiper-wrapper {
            align-items: stretch;
            margin-bottom: 2rem;
            padding-top: 10px;
        }
    }
    & .card {
        overflow: hidden;
        border-radius: 7px;
        border: 1px solid $gray-100;
        background: #fff;
        width: 100%;
        height: 100%;
        @include media-breakpoint-down(xs) {
            width: 100%;
            max-width: 240px;
        }
        &:hover {
            box-shadow: 0px 0px 25px 0px rgba(169, 169, 169, 0.6);
        }
        min-height: 360px;
        //min-width: 250px;
        & > img {
            flex-shrink: 0;
            height: 200px;
            width: 100%;
            object-fit: cover;
            object-position: top center;
        }
        & .card-body {
            flex-grow: 1;
            flex-shrink: 0;
            font-weight: 600;
            position: relative;
            & .card-text {
                @include font-size(1rem);
                padding-top: 1rem;
            }
            & .date {
                display: block;
                padding: 6px 12px;
                border-radius: 10px;
                background: #fff8d2;
                position: absolute;
                left: 20px;
                color: $gray-900;
                font-weight: 400;
            }
            & .btn {
                --bs-btn-padding-y: 6px;
                --bs-btn-padding-x: 28px;
            }
            padding: 24px 26px 10px 26px;
            text-overflow: ellipsis;
            line-height: 150%;
        }
        & .card-footer {
            --bs-card-cap-bg: white;
            padding: 0 26px 22px 26px;

            border-top: none;
            & .btn {
                --bs-btn-padding-y: 6px;
                --bs-btn-padding-x: 28px;
                @include media-breakpoint-down(md) {
                    width: 100%;
                }
            }
        }
    }
}
