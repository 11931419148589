.why-kim {
    background-color: #fff;
    &__list {
        width: 100%;
        &-item {
            height: auto;
            overflow: hidden;
            display: flex;
            align-items: center;
            counter-increment: count 1;
            & .image {
                position: relative;
                &::after {
                    content: counter(count, decimal);
                    position: absolute;
                    left: 50%;
                    transform: translateX(-50%);
                    top: 19px;
                    font-size: 1.875rem;
                    line-height: 1;
                    color: #fff;
                    font-weight: 700;
                    @include media-breakpoint-down(sm) {
                        font-size: 1.25rem;
                        top: 15px;
                    }
                }
                & img {
                    width: 100px;
                    height: 100px;
                    object-fit: contain;
                    object-position: center;
                    @include media-breakpoint-down(sm) {
                        width: 70px;
                        height: 70px;
                    }
                }
            }
            & .text {
                //@include font-size(1rem);
                font-size: inherit;
                @include margin-left(1rem);
                flex-grow: 1;
                color: $gray-900;
                line-height: 140%;
                overflow: hidden;
                white-space: normal;
            }
        }
    }
    &__list-wrapper {
        width: 100%;
        display: grid;
        column-gap: 1rem;
        row-gap: 2.5rem;
        counter-reset: count 0;
        grid-template-columns: repeat(4, 1fr);
        grid-template-rows: auto;
        @include media-breakpoint-down(xl) {
            grid-template-columns: repeat(2, 1fr);
        }
        @include media-breakpoint-down(md) {
            grid-template-columns: repeat(1, 1fr);
        }
    }
}
