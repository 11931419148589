.doctors-block {
    select {
        //min-width: 200px;
        max-width: 100%;
        overflow: hidden;
    }
    &__form,
    .filters_form {
        &::after,
        &::before {
            content: '';
            flex-basis: calc(100% / 12);
        }
        margin-bottom: 1.5rem;
        @include media-breakpoint-down(lg) {
            margin-bottom: 1.25rem;
        }
        & .form-items {
            flex-shrink: 0;
            & input:not([type='hidden']) {
                height: min-content;
            }
            & input::placeholder {
                color: #727272;
                font-size: 1rem;
                @include media-breakpoint-down(lg) {
                    font-size: 0.875rem;
                }
            }
            & .ss-main {
                padding: 0.375rem 0.5rem;
                height: auto;
            }
            .ss-values {
                flex-wrap: wrap;
                height: min-content;
                font-size: 0.875rem;
            }
            .ss-value {
                max-width: 100%;
                width: fit-content;
                & .ss-value-text {
                    overflow-x: hidden;
                    text-overflow: ellipsis;
                }
            }
            & .ss-placeholder {
                //padding-left: 0.5rem;
                //padding: 0.375rem 0.5rem;
                color: $gray-700;
            }
            border-radius: 10px;
            padding: 20px 24px;
            background: #eff8f3;
            align-items: center;
            display: flex;
            //flex-wrap: nowrap;
            @include media-breakpoint-down(lg) {
                flex-direction: column;
                row-gap: 0.25rem;
            }
            //column-gap: 1rem;
            justify-content: space-between;
            & > .form-item {
                flex-basis: calc(100% / 5);
                max-width: 20%;
                box-sizing: border-box;
                @include media-breakpoint-only(lg) {
                    flex-basis: calc(100% / 4);
                    max-width: 25%;
                    &:last-of-type {
                        display: none;
                    }
                }
                @include media-breakpoint-down(lg) {
                    flex-basis: 100%;
                    width: 100%;
                    max-width: unset;
                }
            }
            & [type='reset'] {
                width: 100%;
                @include media-breakpoint-only(lg) {
                    @include visually-hidden();
                    width: 0;
                }
                @include media-breakpoint-only(md) {
                    width: 50%;
                }
            }
        }
    }

    &__select,
    & [name='q'] {
        //height: 100%;
        width: 100%;
    }
    &__select {
        @include font-size(1rem);
        @include media-breakpoint-down(lg) {
            font-size: 0.875rem;
        }
        & [data-placeholder] {
            background-color: #eff8f3;
        }
        & .ss-values {
            flex-wrap: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;
            @include media-breakpoint-down(lg) {
                //font-size: 1rem;
            }
        }
    }
    &__list {
        height: auto;
        align-self: stretch;
        overflow: visible;
        text-align: center;
        & #doctorsListData {
            margin-bottom: 2rem !important;
            gap: auto;
            & .card.doctor-card {
                max-width: unset;
            }
            @include media-breakpoint-down(md) {
                & .card.doctor-card {
                    max-width: 300px;
                    margin: 0 auto;
                }
            }
        }
        & .btn-more {
            margin-bottom: 2rem;
            align-self: center;
        }
    }
}
