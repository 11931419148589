.page__banners-slider {
    //margin-bottom: 1.5rem;
    padding: 0;
    .page__banner {
        & .banner__container {
            padding: 0 !important;
            height: 100%;
            & .carousel-inner {
                overflow: hidden;
                & .carousel-item {
                    height: 100%;
                }
            }
        }
        &.banner-345 {
            @include media-breakpoint-down(sm) {
                height: 150px; //166px;
            }
            @include media-breakpoint-between(sm, md) {
                height: 210px; //160px;
            }
            @include media-breakpoint-up(md) {
                height: 270px; //240px;
            }
            @include media-breakpoint-up(lg) {
                height: 315px; //250px;
            }
            @include media-breakpoint-up(xl) {
                height: 344px;
            }
        }
        & .banner__wrapper {
            position: relative;
            overflow: hidden;
            border-radius: 8px;
            width: 100%;
            height: 100%;
            & .banner__image {
                width: 100%;
                height: 100%;
                object-fit: cover;
                object-position: center top;
            }
            & .banner__content {
                white-space: normal;
                line-height: 1.1;
                //@include adaptiv-value('padding-left', 48, 16, 1);
                @include padding(3rem);
                @include media-breakpoint-down(xl) {
                    @include padding(2rem);
                }
                @include media-breakpoint-down(lg) {
                    @include padding(1.5rem);
                }
                @include media-breakpoint-down(md) {
                    @include padding(1rem);
                }
                @include media-breakpoint-down(sm) {
                    @include padding(1rem);
                }
                & .banner__caption {
                    @include font-size(1.625rem);
                    @include media-breakpoint-down(xl) {
                        @include font-size(1.25rem);
                    }
                    @include media-breakpoint-down(lg) {
                        @include font-size(1.125rem);
                    }
                    @include media-breakpoint-down(md) {
                        @include font-size(1);
                    }
                    @include media-breakpoint-down(sm) {
                        @include font-size(0.75rem);
                    }
                }
                & strong {
                    color: $primary;
                }
                & .banner__text {
                    & > p {
                        margin-bottom: 0 !important;
                    }
                }
            }
        }
    }
}
