.banner-form {
    & form {
        & .form-check-label {
            @include font-size(0.75rem);
        }
    }
    &__wrapper {
        display: flex;
        align-items: center;
        height: 368px;
        background:
            no-repeat right url('/assets/img/banner3-doctor@1x.png'),
            linear-gradient(90deg, #c8d5fb -23.83%, rgba(200, 213, 251, 0) 104.58%);
        @include media-breakpoint-down(sm) {
            background: no-repeat right linear-gradient(90deg, #c8d5fb -23.83%, rgba(200, 213, 251, 0) 104.58%);
        }
        border-radius: 7px;
        //padding: 32px 45px;

        & #needConsultation {
            font-size: inherit;
            & input:focus {
                box-shadow: 0 0 0 0.25rem rgba(2, 86, 38, 0.25);
            }
            & #needConsultationAgreement {
                &:checked {
                    background-color: $green;
                    border-color: $green;
                }
            }
            & .form-check-label {
                @include font-size(0.75rem);
            }
            & a {
                color: $green;
                &:hover {
                    text-decoration: underline;
                }
            }
        }
    }
}
