.reviews-block {
    &__list {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        row-gap: 2rem;
        margin-bottom: 4rem;
        & #reviewsListData {
            display: flex;
            flex-direction: column;
            row-gap: 20px;
            & .list-item {
                width: 100%;
                padding: 1.5rem;
                border-radius: 7px;
                background: rgba(233, 238, 255, 0.65);
                color: $gray-700;
                & .review-text {
                    margin-bottom: 2rem;
                    font-weight: 300;
                    line-height: 160%;
                    &.answer {
                        padding-left: 100px;
                    }
                }

                &__review {
                    display: flex;
                    flex-wrap: nowrap;
                    @include media-breakpoint-down(md) {
                        flex-wrap: wrap;
                    }
                    align-items: center;
                    margin-bottom: 1.5rem;
                    &.answer {
                        padding-left: 100px;
                        & .list-item__rating {
                            display: none;
                        }
                    }
                    & .list-item__name {
                        flex-grow: 1;
                        flex-shrink: 0;
                        flex-basis: 25%;
                        @include font-size(1.125rem);
                        font-weight: 600;
                        line-height: 120%;
                        @include media-breakpoint-down(lg) {
                            flex-basis: 50%;
                        }
                        @include media-breakpoint-down(md) {
                            flex-basis: 100%;
                            margin-bottom: 0.5rem;
                        }
                    }
                    & .list-item__rating {
                        flex-basis: 75%;
                        @include media-breakpoint-down(lg) {
                            flex-basis: 50%;
                        }
                        @include media-breakpoint-down(md) {
                            flex-basis: 100%;
                        }
                        flex-grow: 1;
                        flex-shrink: 1;
                        display: flex;
                        align-items: center;
                        column-gap: 1rem;
                        & .date {
                            line-height: normal;
                            @include font-size(0.75rem);
                            margin-right: 1rem;
                        }
                        & .rating {
                            display: flex;
                            align-items: center;
                            height: min-content;
                            & > svg {
                                fill: none;
                            }
                        }
                    }
                }
            }
        }
    }
    &__new {
        width: 100%;
        border-radius: 10px;
        border: 1px solid #f0f0f0;
        background: #f7f8f9;
        padding: 1.5rem;
        position: relative;
        @include media-breakpoint-down(lg) {
            background: #fff;
            padding: 0;
            margin: 0;
            & .modal-dialog {
                //margin: 3rem 5rem;
            }
            & .modal-content {
                border-width: 0;
            }
        }
        & .btn-close {
            position: absolute;
            top: 5px;
            right: 10px;
            width: 43px;
            height: 43px;
            background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg width='43' height='44' fill='none' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M11.266 33.3L9.94 31.946c-.119-.156-.235-.314-.356-.468-1.978-2.526-3.142-5.395-3.315-8.629-.294-5.442 1.652-9.911 5.855-13.26 3.168-2.525 6.828-3.498 10.815-3.035 4.016.467 7.322 2.336 9.896 5.514 1.907 2.351 3.001 5.075 3.294 8.114.365 3.79-.505 7.268-2.637 10.403-.317.465-.667.906-1.003 1.358l-1.326 1.356c-.292.232-.58.47-.875.696-2.422 1.857-5.146 2.9-8.168 3.077-3.469.204-6.646-.702-9.504-2.729-.463-.328-.9-.693-1.35-1.041zm.659-2.039a12.951 12.951 0 0018.56.02c5.134-5.218 5.14-13.753.022-18.994a12.952 12.952 0 00-18.56-.02c-5.13 5.217-5.137 13.756-.022 18.994z' fill='%23009846'/%3e%3cpath d='M21.216 20.418l.25-.255 4.268-4.363c.533-.545 1.367-.371 1.588.327.13.412.005.757-.292 1.059a1333.5 1333.5 0 00-4.208 4.3l-.28.287.244.25 4.268 4.363c.514.525.384 1.348-.255 1.606-.417.17-.777.053-1.092-.27-1.413-1.45-2.828-2.899-4.246-4.343l-.245-.25-.24.244-4.268 4.363c-.518.53-1.322.403-1.574-.249-.165-.424-.06-.793.256-1.118 1.42-1.444 2.836-2.89 4.25-4.34l.25-.255-.236-.24c-1.43-1.463-2.861-2.92-4.288-4.384-.522-.534-.359-1.374.308-1.612.403-.144.744-.025 1.04.278 1.413 1.45 2.829 2.897 4.247 4.342l.255.26z' fill='%23009846'/%3e%3c/svg%3e");
            background-size: contain;
            background-repeat: no-repeat;
        }
        & form {
            width: 100%;
            @include media-breakpoint-down(lg) {
                flex-direction: column;
            }
            & ::placeholder {
                color: $gray-600;
                @include font-size(0.875rem);
            }
            & textarea {
                height: 165px;
            }
            & [type='submit'] {
                //--bs-btn-padding-x: 4rem;
                width: auto;
            }
            & .form-control {
                --bs-border-color: $gray-100;
                border: 1px solid #f0f0f0;
                &:focus {
                    box-shadow: 0 0 0 0.25rem rgba(2, 86, 38, 0.25);
                }
            }
            & .form-check {
                margin-bottom: 2rem;
                display: flex;
                justify-content: center;
                width: max-content;
                & .form-check-input {
                    width: 20px;
                    height: 20px;
                    border-radius: 8px;
                    &:focus {
                        box-shadow: 0 0 0 0.25rem rgba(2, 86, 38, 0.25);
                    }
                }
                & .form-check-label {
                    width: 75%;
                    font-weight: 300;
                    line-height: 150%;
                    color: $gray-600;
                    @include font-size(0.75rem);
                    padding: 0;
                }
                & a {
                    color: $green;
                    font-weight: 600;
                    &:hover {
                        text-decoration: underline;
                    }
                }
            }
            & #reviewConsultationAgreement {
                &:checked {
                    background-color: $green;
                    border-color: $green;
                }
            }
        }
        & .form-caption {
            margin-bottom: 0.75rem;
            color: $gray-700;
        }
        & .rating {
            margin-bottom: 2rem;
            & > svg {
                fill: none;
            }
        }
    }
}
