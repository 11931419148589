.page-banner {
    margin: rfs-fluid-value(1.5rem) auto;
    background-color: transparent;
    padding: 0;
    &__container {
        width: 100%;
    }
    &__wrapper {
        position: relative;
        overflow: hidden;
        border-radius: 8px;
        width: 100%;
        height: 124px;
        font-size: 1.625rem;
        & .banner__text {
            z-index: inherit;
            white-space: nowrap;
            padding-left: 5rem;
            //white-space: normal;
            //@include adaptiv-value('padding-left', 48, 16, 1);
        }
        & .banner__action {
            z-index: inherit;
            //padding-right: 5rem;
            & .btn {
                --bs-btn-padding-x: 1.625em;
                --bs-btn-padding-y: 0.5625em;
                & .banner__action {
                    //padding-right: 3rem;
                    & .btn {
                        --bs-btn-padding-x: 1.25em;
                        --bs-btn-padding-y: 0.375em;
                        --bs-btn-font-size: 1rem;
                    }
                }
            }
        }
        & .banner__image {
            width: 100%;
            height: 100%;
            object-fit: cover;
            object-position: center top;
        }
        @include media-breakpoint-down(xl) {
            height: 85px;
            font-size: 1.25rem;
            & .banner__action {
                & .btn {
                    --bs-btn-font-size: 0.875rem;
                }
            }
        }
        @include media-breakpoint-down(lg) {
            font-size: 1.125rem;
            & .banner__text {
                //padding-left: 3rem;
            }
            & .banner__action {
                //padding-right: 3rem;
                & .btn {
                    --bs-btn-padding-x: 1.25em;
                    --bs-btn-padding-y: 0.375em;
                    --bs-btn-font-size: 0.75rem;
                }
            }
        }
        @include media-breakpoint-down(md) {
            height: 62px;
            font-size: 1rem;
            & .banner__text {
                padding-left: 2rem;
            }
            & .banner__action {
                //padding-right: 2rem;
                & .btn {
                    --bs-btn-padding-x: 10px;
                    --bs-btn-padding-y: 6px;
                    --bs-btn-font-size: 0.625rem;
                }
            }
        }
        @include media-breakpoint-down(sm) {
            font-size: 0.75rem;
            & .banner__text {
                padding-left: 2rem;
                white-space: normal;
            }
            & .banner__action {
                //padding-right: 2rem;
                & .btn {
                    --bs-btn-padding-x: 10px;
                    --bs-btn-padding-y: 6px;
                    --bs-btn-font-size: 0.5rem;
                }
            }
        }
    }
}
