.info__block {
    /*     @include media-breakpoint-up(xl) {
        font-size: 1rem;
    }
    @include media-breakpoint-down(xl) {
        font-size: 0.875rem;
    } */
    font-size: inherit;
    padding: 2em 0;
    .info__block-wrapper {
        display: flex;
        column-gap: 2rem;
        & .info__block-text {
            @include media-breakpoint-up(lg) {
                order: 0 !important;
            }
            @include media-breakpoint-down(lg) {
                order: 1 !important;
            }
            @include media-breakpoint-down(md) {
                order: 0 !important;
            }
            flex-shrink: 1;
            flex-grow: 1;
            width: auto;
            color: $gray-900;
            padding-bottom: 1.5em;
            & > p {
                font-size: 1em;
                font-weight: 400;
            }
            & .info__block-caption {
                font-size: 1.5em;
                font-weight: 600;
            }
            & .info__block-cite {
                color: $gray-700;
                font-weight: 400;
                line-height: 1.5em;
                margin-top: 1.25em;
                margin-bottom: 2.5em;
            }
            & a.btn {
                --bs-btn-padding-y: 0.5em;
                --bs-btn-padding-x: 3.5em;
                font-size: 1em;
                @include media-breakpoint-down(lg) {
                    float: right;
                }
                @include media-breakpoint-down(sm) {
                    width: 100%;
                }
            }
        }
        & .info__block-image {
            @include media-breakpoint-up(lg) {
                order: 1;
            }
            @include media-breakpoint-down(lg) {
                order: 0;
            }
            @include media-breakpoint-down(md) {
                order: 1;
            }

            overflow: hidden;
            border-radius: var(--bs-border-radius);
            width: 50%;
            flex: 1 0 45%;
            & img {
                width: 100%;
                max-height: 350px;
                height: 100%;
                object-fit: cover;
                object-position: top center;
            }
        }
    }

    @include media-breakpoint-down(lg) {
        & .info__block-wrapper {
            & .info__block-image {
                flex: 1 0 30%;
                width: 33.3%;
            }
        }
    }
    @include media-breakpoint-down(md) {
    }
    @include media-breakpoint-down(sm) {
    }
}
