@font-face {
    font-family: 'Open Sans';
    src: url('/assets/fonts/OpenSans-Light.eot');
    src:
        local('Open Sans Light'),
        local('OpenSans-Light'),
        url('/assets/fonts/OpenSans-Light.eot?#iefix') format('embedded-opentype'),
        url('/assets/fonts/OpenSans-Light.woff') format('woff'),
        url('/assets/fonts/OpenSans-Light.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;
}
@font-face {
    font-family: 'Open Sans';
    src: url('/assets/fonts/OpenSans-Regular.eot');
    src:
        local('Open Sans Regular'),
        local('OpenSans-Regular'),
        url('/assets/fonts/OpenSans-Regular.eot?#iefix') format('embedded-opentype'),
        url('/assets/fonts/OpenSans-Regular.woff') format('woff'),
        url('/assets/fonts/OpenSans-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'Open Sans';
    src: url('/assets/fonts/OpenSans-SemiBold.eot');
    src:
        local('Open Sans SemiBold'),
        local('OpenSans-SemiBold'),
        url('/') format('embedded-opentype'),
        url('/assets/fonts/OpenSans-SemiBold.woff') format('woff'),
        url('/assets/fonts/OpenSans-SemiBold.ttf') format('truetype');
    font-weight: 600;
    font-style: normal;
}
@font-face {
    font-family: 'svg4kim';
    src:
        url('/assets/fonts/svg4kim.ttf?yl45en') format('truetype'),
        url('/assets/fonts/svg4kim.woff?yl45en') format('woff'),
        url('/assets/fonts/svg4kim.svg?yl45en#svg4kim') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: block;
}
@font-face {
    font-family: 'bootstrap-icons';
    src:
        url('/assets/fonts/bootstrap-icons.woff') format('woff'),
        url('/assets/fonts/bootstrap-icons.woff2') format('woff2');
}
