.main__services {
    & .nav-wrapper {
        background-color: transparent;
        box-shadow: 0px 0px 20px rgba(106, 106, 106, 0.15);
        border-radius: var(--bs-border-radius);
        & .nav-link:not(.active) > .pict {
            -webkit-filter: grayscale(1); /* Webkit браузеры */
            filter: gray; /* для IE6-9 */
            filter: grayscale(1); /* W3C */
            & > img,
            & > svg {
                -webkit-filter: grayscale(1); /* Webkit браузеры */
                filter: gray; /* для IE6-9 */
                filter: grayscale(1); /* W3C */
            }
        }
        & .nav-link:not(.active) > svg,
        & .nav-link:not(.active) > img {
            -webkit-filter: grayscale(1); /* Webkit браузеры */
            filter: gray; /* для IE6-9 */
            filter: grayscale(1); /* W3C */
        }
        @include media-breakpoint-down(sm) {
            overflow: visible;
            box-shadow: none;
            margin-bottom: 1em;
            & nav {
                overflow: hidden;
                margin-bottom: 1em;
                background-color: white;
                --bs-border-radius: 8px;
                box-shadow: 0px 0px 20px rgba(106, 106, 106, 0.15);
            }
        }
    }
    & .tab-content {
        margin-bottom: 3rem;
        & .filter-buttons > .btn {
            font-weight: 600;
            display: inline-flex;
            align-items: center;
            fill: currentColor;
            --bs-btn-padding-x: 0.75rem;
            --bs-btn-padding-y: 0.375rem;
            & svg {
                display: inline-block;
                vertical-align: top;
                margin-right: 5px;
                width: 24px;
                height: 24px;
                object-fit: contain;
                object-position: center left;
            }
            @include media-breakpoint-down(lg) {
                font-size: 0.75rem;
            }
            @include media-breakpoint-down(md) {
                font-size: 0.625rem;
                --bs-btn-padding-x: 1rem;
            }
            @include media-breakpoint-down(sm) {
                margin-bottom: 1em;
            }
            & span:not([class^='icon-']) {
                font-size: inherit;
            }
        }
        & .alphabet__list {
            &-wrapper {
                &._adult,
                &._adult a {
                    color: $blue !important;
                    ---bs-link-color-rgb: rgb(01, 30, 82);
                    &:focus,
                    &:focus-visible,
                    &:visited {
                        color: $blue;
                    }
                }
                &._child,
                &._child a {
                    color: $green;
                    &:focus,
                    &:focus-visible,
                    &:visited {
                        color: currentColor;
                    }
                }
                width: 100%;
                column-count: 4;
                column-gap: 20px;
                @include media-breakpoint-down(xl) {
                    column-count: 3;
                }
                @include media-breakpoint-down(lg) {
                    column-count: 2;
                }
                @include media-breakpoint-down(md) {
                    column-count: 1;
                }
            }
            &-item {
            }
            &-letter {
                color: inherit;
            }
            &-content {
                & .content-item {
                    break-inside: avoid;
                    & a:hover {
                        font-weight: 700;
                        color: inherit;
                    }
                }
            }
        }
        & .services-diagnosis {
            color: $green;
            &__wrapper {
                color: $gray-700;
                & .col {
                    margin-top: 1rem;
                }
                & .card {
                    --bs-card-spacer-x: 1.5rem;
                    --bs-card-spacer-y: 0.25rem;
                    --bs-card-title-spacer-y: 0.75rem;
                    --bs-card-border-width: 0;
                    --bs-card-border-color: transparent;

                    & .list-group {
                        padding-left: 18px;
                        & li {
                            padding-bottom: 0.5rem;
                            @include media-breakpoint-down(md) {
                                padding-bottom: 0.25rem;
                            }
                            & a:hover {
                                font-weight: 600;
                                color: $green;
                            }
                        }
                    }
                    & .card-header h4 {
                        font-weight: 600;
                        color: $green;
                    }
                    & .card-footer,
                    & .card-header {
                        background: inherit;
                    }
                    & .btn {
                        width: 100%;
                        max-width: 210px;
                        font-size: inherit;
                        --bs-btn-padding-x: 2rem;
                    }
                }
            }
        }
    }
    & .services-tests {
        &__wrapper {
            & .icon-link {
                color: $gray-700;
                fill: $green;
                & > svg {
                    color: $green;
                    width: 40px;
                    height: 40px;
                    object-fit: contain;
                    object-position: center;
                    fill: currentColor;
                    margin-right: 1rem;
                }
                &:hover {
                    color: $green;
                    font-weight: 600;
                }
            }
        }
    }
    & .box-shadow {
        box-shadow: 0px 0px 20px 0px rgba(106, 106, 106, 0.15);
    }
}
