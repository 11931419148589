.testimonials-block {
    width: 100%;
    min-height: 375px;
    background-image: url('../img/bg_reviews__slider.jpg');
    background-position: top center;
    background-size: cover;
    &__caption {
        font-weight: 600;
    }
    & .reviews-slider {
        width: 100%;
        & .reviews-item {
            box-sizing: border-box;
            min-height: 190px;
            border-radius: 7px;
            opacity: 0.65;
            background: #fff;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            gap: 10px;
            padding: 24px;
            @include media-breakpoint-down(xl) {
                min-height: 173px;
                padding: 24px 16px;
            }
            @include media-breakpoint-down(lg) {
                min-height: 163px;
                background: #fff;
                padding: 16px;
            }
            @include media-breakpoint-down(md) {
                background: #fff;
                min-height: 163px;
            }
            @include media-breakpoint-down(sm) {
                min-width: 242px;
            }
            & .caption {
                width: 100%;
                display: flex;
                flex-wrap: nowrap;
                justify-content: stretch;
                align-items: center;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
                color: $gray-700;
                flex: 1 1 25%;
                max-height: 245px;
                text-overflow: ellipsis;
                //overflow: hidden;
                & .sender {
                    @include font-size(1rem);
                    font-style: normal;
                    font-weight: 600;
                    line-height: 120%;
                    flex: 0 0 33%;
                    text-align: left;
                    @include media-breakpoint-down(sm) {
                        @include font-size(0.875rem);
                    }
                }
                & .date {
                    display: block;
                    height: 100%;
                    font-size: 0.75em;
                    flex: 1 1 33%;
                    padding-left: 0.75em;
                    padding-right: 0.75em;
                    padding-top: 3px;
                    text-align: left;
                }
                & .rating {
                    display: inline;
                    text-align: right;
                }
            }
            & .review-link {
                display: flex;
                width: 100%;
                align-items: flex-end;
                justify-content: flex-start;
                @include font-size(1rem);
                @include media-breakpoint-down(sm) {
                    @include font-size(0.75rem);
                }
            }
            & .review {
                @include font-size(0.875rem);
                max-height: 235px;
                display: block;
                text-overflow: ellipsis;
                overflow: hidden;
                margin-bottom: 0;
                & > p {
                }
            }
        }
    }

    & > a[type='button'] {
        flex: 0 0 max-content;
        margin-right: 2em;
        width: max-content;
        @include media-breakpoint-down(xl) {
            margin-right: 1.5em;
        }
        @include media-breakpoint-down(lg) {
            margin-right: 1em;
        }
        @include media-breakpoint-down(md) {
            margin-right: 0.5em;
        }
        @include media-breakpoint-down(sm) {
            margin-right: 0;
        }
        &:last-child {
            margin-right: 0;
        }
    }
}
