h1,
h2,
h3,
h4,
h5,
h6,
ul {
    margin: 0;
    padding: 0;
}
.fs-12 {
    font-size: 12px;
}
.fs-13 {
    font-size: 13px;
}
.fs-9 {
    font-size: 9px;
}
section {
    width: 100%;
    display: block;
    height: auto;
    clear: both;
}

.btn-light-green {
    @include button-variant(#eff8f3, #eff8f3, $gray-900, #009846, #009846, #fff, #009846, #009846, #fff, $gray-300, $gray-300, $gray-900);
}
.btn-green {
    @include button-variant($green, $green, #fff, $green, $green, #fff, $green, $green, #fff, $gray-600, $gray-600, #fff);
}

.btn-outline-green {
    --bs-btn-bg: white;
    @include button-outline-variant($green, white, $green, $green, white);
}
.button-size-sm {
    @include button-size(0.5rem, 1.6rem, 0.875rem, 8px);
    font-weight: 600;
}
.button-size {
    @include button-size(0.5625rem, 1.6rem, 1rem, 8px);
    font-weight: 600;
}
.button-size-lg {
    @include button-size(0.5625rem, 1.6rem, 1.125rem, 8px);
    font-weight: 600;
}
.no-btn {
    @include no-btn();
}
.btn-shadow {
    &:hover {
        box-shadow: 0px 2px 5px 0px rgba($color: #000000, $alpha: 0.1);
    }
    &.btn-primary:hover {
        box-shadow: 0px 2px 5px 0px #013082 !important;
    }
    &.btn-green:hover,
    &.btn-outline-green:hover {
        box-shadow: 0px 2px 5px 0px #025626 !important;
    }
}
img:not([src]):not([srcset]) {
    visibility: hidden;
}
img.pre-loader {
    width: 42px;
    height: 42px;
    position: absolute;
    left: 50%;
    top: 50%;
    margin-left: -21px;
    margin-top: -21px;
    z-index: 10;
    transform-origin: 50%;
    box-sizing: border-box;
    border: 4px solid #fff;
    border-radius: 50%;
    border-top-color: transparent;
    animation: swiper-preloader-spin 1s infinite linear;
}
body {
    &._lock {
        overflow: hidden;
        position: relative;
        & .bg__lock {
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            z-index: 1;
            background-color: rgba($color: #000000, $alpha: 0.5);
        }
    }
    font-family: 'Open Sans', sans-serif;
    @include font-size(1rem);
    @include media-breakpoint-down(lg) {
        @include font-size(0.875rem);
    }
    @include media-breakpoint-down(sm) {
        @include font-size(0.75rem);
    }
}
.document__wrapper {
    width: 100%;
    display: flex;
    flex-direction: column;
    min-height: 100vh;
}
.alphabet__list {
    width: 100%;
    //@include font-size(1rem);
    &-content {
        break-inside: avoid-column;
        margin-left: 20px;
        flex-grow: 1;
        & > li:first-of-type {
            margin-top: 0;
        }
        & > .content-item {
            //@include font-size(1rem);
            font-size: inherit;
            font-weight: 400;
            line-height: normal;
        }
    }
    &-wrapper {
        background-color: #fff;
        border: 1px solid $gray-100;
        border-radius: 7px;
        @include padding(2.5rem);
    }
    &-letter {
        display: table-cell;
        vertical-align: top;
        width: 20px;
        font-size: 1.375em;
        line-height: 1;
        font-weight: 600;
    }
    &-item {
        display: flex;
        //font-size: 1rem;
        @include margin-bottom(2rem);
    }
}
section {
    @include padding-top(2rem);
    @include padding-bottom(3rem);
    display: flex;
    flex-direction: column;
    justify-content: start;
    & [class$='__wrapper'] {
    }
    & [class$='__container'] {
        --bs-gutter-y: 0;
        --bs-gutter-x: 0;
        width: 100%;
        height: inherit;
        max-width: 1320px;
        padding-right: 1.5rem;
        padding-left: 1.5rem;
        @include media-breakpoint-between(xl, xxl) {
            max-width: 1140px;
        }
        @include media-breakpoint-up(xl) {
            padding-right: 0;
            padding-left: 0;
            margin: 0 auto;
        }
        & h1,
        & [class$='__caption'] {
            font-weight: 600;
            color: $gray-900;
            margin-bottom: 1.5rem;
            @include font-size(2.5rem);
            @include media-breakpoint-down(xl) {
                @include font-size(2rem);
            }
            @include media-breakpoint-down(lg) {
                @include font-size(1.5rem);
            }
        }
        & h2,
        & [class$='__caption'] {
            @include font-size(2rem);
            @include media-breakpoint-down(xl) {
                @include font-size(1.5rem);
            }
            @include media-breakpoint-down(lg) {
                @include font-size(1.25rem);
            }
            @include media-breakpoint-down(md) {
                @include font-size(1.125rem);
            }
            margin-bottom: 2rem;
            font-weight: 600;
        }
        & [class$='__caption-with-link'] {
            display: flex;
            flex-wrap: nowrap;
            justify-content: stretch;
            align-items: center;
            gap: 2em;
            margin-bottom: 2rem;
            @include media-breakpoint-down(xl) {
                gap: 1em;
            }
            & [class$='__caption'] {
                flex: 1 1 auto !important;
                margin-bottom: 0;
            }
        }
    }
    &:first-of-type {
        margin-top: 0;
    }
}
.btn.not-allowed {
    cursor: not-allowed !important;
    //--bs-btn-hover-border-color:#5a78ae;
    //--bs-btn-hover-bg: #5a78ae;
    //--bs-btn-bg: #5a78ae;
    //--bs-btn-border-color: #5a78ae;
}
button.busy:hover {
    --bs-btn-hover-bg: #5a78ae;
}
.visually-hidden-down-lg {
    @include media-breakpoint-down(lg) {
        @include visually-hidden;
    }
}
.no-markers > a {
    //display: list-item;
    text-align: start;
}
#doc-map {
    min-height: 300px;
    @include media-breakpoint-down(lg) {
        min-height: 200px;
    }
}
.header__menu .navbar-nav .nav-link .dropdown-toggle::after {
    margin-left: 0.5em;
}
.footer__content-links {
    max-width: 100%;
    overflow-x: scroll;
    @include media-breakpoint-down(md) {
        & .btn {
            --bs-btn-font-size: 0.75rem;
            --bs-btn-padding-x: 0.5rem;
            --bs-btn-padding-y: 0.25rem;
            --bs-btn-line-height: 1;
            --bs-btn-color: #111;
        }
    }
}
