.htmx-indicator {
    display: none;
}

.htmx-request .htmx-indicator {
    @include visually-hidden;
    width: 100%;
    position: absolute;
    top: 50%;
    left: 50%;
}

.htmx-request.htmx-indicator {
    display: block;
    height: 200px;
    position: absolute;
    top: 50%;
    left: 50%;
}
