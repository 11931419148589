body._menu-opened {
    /* background-color: rgba(0, 0, 0, 0.5);
    overflow-y: hidden;
    padding-right: 0px !important; */
}
body._lock {
    & .header .header__menu.navbar #main__menu {
        z-index: 1055 !important;
    }
    & .header {
        position: sticky;
        z-index: 1055 !important;
    }
}
.header {
    z-index: 1;
    background-color: #fff;
    margin-bottom: 1rem;
    box-shadow: 0px 0px 15px 0px rgba(160, 160, 160, 0.15);
    & .header__wrapper {
        width: 100%;
        height: 166px;
        background-image: linear-gradient($gray-100, $gray-100);
        background-size: 100% 1px;
        background-repeat: no-repeat;
        background-position: left 0 bottom 40px;
        /* background-image: url('/assets/img/header-bg.gif');
        background-position: top left;
        background-repeat: repeat-x; */
        @include media-breakpoint-down(lg) {
            height: 116px;
            background-image: none;
            background-color: #fff;
        }
        @include media-breakpoint-down(md) {
            height: 98px;
        }
        @include media-breakpoint-down(sm) {
            height: 82px;
        }
    }
    & .container-fluid.container-xl {
        height: inherit;
        --bs-gutter-x: 0;
        //padding-right: 1.5rem !important;
        //padding-left: 1.5rem !important;
        //padding-right: 0;
        //padding-left: 0;
        //--bs-gutter-x: 0;
    }
    & .header__inner {
        width: 100%;
        height: inherit;
        display: grid;
        grid-template-columns: repeat(12, 1fr);
        grid-template-rows: 48px 78px 40px;
        @include media-breakpoint-down(lg) {
            grid-template-rows: 40px 36px 40px;
        }
        @include media-breakpoint-down(md) {
            grid-template-rows: 51px 47px;
            column-gap: 10px;
        }
        @include media-breakpoint-down(sm) {
            grid-template-rows: 36px 46px;
            column-gap: 10px;
        }
    }
    & .header__logo {
        grid-column: span 3;
        grid-row: span 2;
        padding: 20px;
        max-width: 90%;
        width: 280px;
        height: 104px;
        @include media-breakpoint-down(xxl) {
            width: 247px;
            height: 91px;
            padding: 18px;
        }
        @include media-breakpoint-down(xl) {
            padding: 10px;
            width: 230px;
        }
        @include media-breakpoint-down(lg) {
            width: 160px;
            height: 66px;
            padding: 10px;
            grid-row: 1/3;
            grid-column: 1/5;
        }
        @include media-breakpoint-down(md) {
            width: 160px;
            height: 51px;
            padding: 6px;
            grid-row: 1/2;
            grid-column: 1/5;
        }
        @include media-breakpoint-down(sm) {
            width: 66px;
            height: 36px;
        }
        border-radius: 0px 0px 5px 5px;
        background: #fff;
        box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.1);
        & .logo-wrapper {
            overflow: hidden;
            width: 100%;
            height: 100%;
            & .logo-img {
                width: 100%;
                height: 100%;
                object-fit: contain;
            }
        }
    }
    & .header__info {
        font-size: 1rem;
        grid-row: span 1;
        grid-column: span 7;
        display: grid;
        grid-template-rows: 1fr 1fr;
        grid-template-columns: repeat(2, 1fr);
        align-content: center;
        justify-content: center;
        grid-auto-flow: column;
        row-gap: 4px;
        column-gap: 0.5rem;
        & .icon-link:nth-child(odd) {
            align-self: end;
            @include media-breakpoint-down(lg) {
                align-self: start;
            }
        }

        & .icon-link:nth-child(even) {
            align-self: start;
        }
        & > a.icon-link {
            height: min-content;
            color: #727272;
            @include font-size(0.8125em);
            @include media-breakpoint-down(xl) {
                @include font-size(0.75em);
            }
            line-height: 1;
            & > svg {
                width: 15px;
                height: 15px;
                object-fit: contain;
                color: inherit;
                fill: #727272;
            }
        }
        @include media-breakpoint-down(lg) {
            display: none;
        }
        @include media-breakpoint-down(md) {
            display: none;
        }
    }
    & .header__socials {
        grid-row: span 1;
        grid-column: span 2;
        display: flex;
        align-items: center;
        justify-content: end;
        gap: 18px;
        @include media-breakpoint-down(lg) {
            grid-row: 1/2;
            grid-column: 8/13;
        }
        @include media-breakpoint-down(md) {
            display: none;
        }
        & > a.icon-link {
            border-radius: 5px;
            overflow: hidden;
            &:hover {
                box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.1);
            }
            & > svg {
                width: 24px;
                height: 24px;
                object-fit: contain;
            }
            @include media-breakpoint-down(lg) {
                width: 20px;
                height: 20px;
            }
            @include media-breakpoint-down(md) {
                width: 20px;
                height: 20px;
            }
        }
    }
    & .header__phone {
        display: grid;
        grid-row: span 1;
        grid-column: span 2;
        color: $gray-700;
        align-content: center;
        justify-items: center;
        //justify-content: start;
        & > a {
            height: min-content;
            padding: 0;
        }
        & a:hover,
        a:focus {
            text-decoration: underline;
        }
        & .phone-text {
            color: inherit;
            //font-size: 0.75em;
            @include rfs(0.75rem, 'font-size');
            font-weight: 400;
            & > svg {
                color: $green;
                fill: $green;
                width: 20px;
                height: 20px;
                object-fit: contain;
            }
            @include media-breakpoint-down(lg) {
                font-size: 9px;
            }
            @include media-breakpoint-down(md) {
                @include visually-hidden();
            }
        }
        & .phone-number {
            --bs-nav-link-padding-y: 0.25rem;
            --bs-nav-link-padding-x: 0;
            @include media-breakpoint-up(xxl) {
                --bs-nav-link-font-size: 1.5rem;
            }
            @include media-breakpoint-down(lg) {
                --bs-nav-link-font-size: 1rem;
                font-size: 1rem;
            }
            @include media-breakpoint-down(md) {
                --bs-nav-link-font-size: 0.875rem;
                font-size: 0.875rem;
            }
            --bs-nav-link-font-size: 1.325rem;
            --bs-nav-link-font-weight: 600;
            --bs-nav-link-color: #000;
        }
        @include media-breakpoint-down(lg) {
            grid-row: 1/3;
            grid-column: 5/8;
        }
        @include media-breakpoint-down(md) {
            grid-row: 1/2;
            grid-column: 5/9;
        }
        @include media-breakpoint-down(sm) {
            grid-column: 4/9;
        }
    }
    & .header__buttons {
        grid-row: span 1;
        grid-column: span 7;
        display: flex;
        flex-wrap: nowrap;
        align-items: center;
        justify-content: end;
        column-gap: 15px;
        & .btn {
            flex: 0 1 auto;
            --bs-btn-border-radius: 5px;
            --bs-btn-padding-x: 1rem;
            --bs-btn-padding-y: 0.5rem;
            --bs-btn-font-family: 'Open Sans';
            --bs-btn-font-size: 0.875rem;
            --bs-btn-font-weight: 600;
            --bs-btn-line-height: normal;
        }
        @include media-breakpoint-down(lg) {
            order: 6;
            grid-row: 3/4;
            grid-column: 1/13;
            justify-content: center;
            column-gap: 8px;
            & .btn {
                --bs-btn-padding-y: 0.1875rem !important;
            }
        }
        @include media-breakpoint-down(md) {
            grid-row: 2/3;
            grid-column: 1/13;
            justify-content: center;
            & .btn {
                --bs-btn-font-size: 0.625rem;
                --bs-btn-padding-x: 0.5rem;
                --bs-btn-padding-y: 0.1875rem;
            }
        }
        @include media-breakpoint-down(sm) {
            column-gap: 6px;
            justify-content: space-between;
            & .btn {
                white-space: normal;
                text-align: center;
                --bs-btn-padding-x: 0.25rem;
                --bs-btn-padding-y: 0.0625rem;
                --bs-btn-font-size: 0.5625rem;
            }
        }
    }
    & .header__menu.navbar {
        width: 100%;
        grid-row: 3/4;
        grid-column: span 10;
        font-size: 1rem;
        align-items: center;
        justify-content: start;
        --bs-navbar-padding-y: 0;
        & .navbar-toggler {
            @include media-breakpoint-down(lg) {
                background-color: white;
                border-color: #009846;
                width: 86px;
                display: inline-flex;
                flex-wrap: nowrap;
                justify-content: flex-start;
                align-items: center;
            }
            @include media-breakpoint-down(sm) {
                width: auto;
            }
            &:hover {
                box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.1);
            }
            & .navbar-toggler-icon {
                &::after {
                    padding-left: 20px;
                    @include media-breakpoint-down(sm) {
                        padding-left: auto;
                    }
                    display: inline;
                    font-size: 11px;
                    vertical-align: middle;
                }
            }
            &:not(.collapsed) {
                color: $green;
                .navbar-toggler-icon {
                    background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' width='16' height='16' class='bi bi-x-lg' fill='#{$green}' viewBox='0 0 16 16'><path d='M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8 2.146 2.854Z'/></svg>");
                    &::after {
                        content: 'Закрыть';
                        @include media-breakpoint-down(sm) {
                            content: '';
                        }
                    }
                }
            }
            &.collapsed {
                background-color: $green;
                --bs-navbar-color: var(--bs-white);
                & .navbar-toggler-icon {
                    --bs-navbar-light-icon-color: white;
                    &::after {
                        content: 'Меню';
                        @include media-breakpoint-down(sm) {
                            content: '';
                        }
                    }
                }
            }
            &:focus {
                box-shadow: none;
            }
        }
        & #main__menu {
            line-height: 1em;
            font-weight: 400;
            padding-left: 0;
            & .menu-wrapper {
                width: 100%;
                @include media-breakpoint-down(lg) {
                    padding: 1rem 1.5rem;
                }
            }
            & .navbar-nav {
                width: 100%;
                display: flex;
                align-items: center;
                justify-content: space-between;
                column-gap: 0;
                @include media-breakpoint-down(lg) {
                    font-size: 0.75em;
                    line-height: normal;
                    justify-content: flex-start;
                    column-gap: 20px;
                }
                //column-gap: 1.5rem;
            }
            & .nav-item {
                flex-grow: 0;
                max-width: 150%;
                @include media-breakpoint-down(lg) {
                    flex-grow: 1;
                }
            }

            & .nav-item:first-child .nav-link {
                padding-left: 0;
            }
            & .dropdown-menu {
                line-height: 1em;
                font-size: 1em;
                font-weight: 400;
                & .dropdown-item {
                    line-height: normal;
                    &.active,
                    &:hover {
                        text-decoration-line: underline;
                        text-decoration-thickness: 2px;
                        text-decoration-color: #009846;
                    }
                    @include media-breakpoint-up(xl) {
                        --bs-dropdown-font-size: 14px;
                        --bs-dropdown-item-padding-y: 0.25rem;
                        --bs-dropdown-item-padding-x: 1.5rem;
                    }
                    @include media-breakpoint-down(xl) {
                        --bs-dropdown-font-size: 13px;
                    }
                }
            }
            & .dropdown-menu.show {
                border-radius: 4px;
                background-color: #fff;
                border: 1px solid var(--grey-stroke, #f0f0f0);
                margin-top: 5px;
                transition: all 2s linear;
                @include media-breakpoint-down(lg) {
                    width: 100%;
                    margin-top: 12px;
                    margin-bottom: 12px;
                    //--bs-nav-link-padding-y: 0.5rem;
                }
            }
            & .nav-link {
                white-space: nowrap;
                font-weight: 400;
                font-size: 1rem;
                color: #000;
                &.active,
                &:hover {
                    text-decoration-thickness: 4px;
                    text-decoration-color: #009846;
                    text-decoration-style: solid;
                    text-decoration-line: underline;
                }
                @include media-breakpoint-down(lg) {
                    font-size: 1.25rem;
                }
            }
            & .nav-item {
                &.btn-group {
                    @include media-breakpoint-up(lg) {
                        & > .dropdown-toggle-split {
                            padding-left: 0;
                            padding-right: 0;
                        }
                    }
                    @include media-breakpoint-down(lg) {
                        width: 100%;
                        display: inline-flex;
                        flex-wrap: wrap;
                        //flex-direction: column;
                    }
                    & > a.nav-link,
                    & > [data-bs-toggle='dropdown'] {
                        margin: 0;
                        border: 0;
                        background-color: transparent;
                        border-radius: 0;
                        cursor: pointer;
                        appearance: none;
                        align-items: center;
                        display: inline-flex;
                        //--bs-nav-link-padding-y: 0;
                    }

                    & > .dropdown-toggle-split::after {
                        margin-left: 0.25rem;
                    }
                }
            }
            & .header__info {
                line-height: normal;
                font-size: 0.75em;
            }
            @include media-breakpoint-up(xl) {
            }
            @include media-breakpoint-down(lg) {
                background-color: #f7f8f9;
                position: fixed;
                top: 116px;
                left: 0;
                right: 0;
                max-height: 75vh;
                z-index: 2;
                & .navbar-nav {
                    align-items: start;
                }
            }
            @include media-breakpoint-down(md) {
                top: 98px;
            }
            @include media-breakpoint-down(sm) {
                top: 82px;
            }
        }
        --bs-navbar-toggler-padding-y: 0.3125rem;
        --bs-navbar-toggler-padding-x: 0.75rem;
        --bs-navbar-toggler-font-size: 0.5rem;
        --bs-navbar-toggler-border-radius: 3px;
        --bs-navbar-toggler-transition: box-shadow 0.15s ease-in-out;
        @include media-breakpoint-down(xl) {
            grid-column: 2/13;
        }
        @include media-breakpoint-down(lg) {
            order: 5;
            grid-row: 2/3;
            grid-column: 12/13;
            justify-content: end;
        }
        @include media-breakpoint-down(md) {
            grid-row: 1/2;
            grid-column: 10/13;
            padding-left: 0.5rem;
            padding-right: 0;
            --bs-navbar-toggler-padding-x: 0.5rem;
        }
        @include media-breakpoint-between(xs, sm) {
            grid-column: 10/13;
        }
    }
    & #search {
        border-radius: 20px;
        grid-row: span 1;
        grid-column: span 2;
        display: flex;
        align-items: center;
        justify-content: end;
        & #navbarSearchContent {
            display: block;
            border-width: 0;
            background: transparent;
            @include media-breakpoint-down(xl) {
                border-bottom-left-radius: 8px !important;
                border-bottom-right-radius: 8px !important;
            }
        }
        & .input-group {
            border-radius: 20px;
            box-shadow: 0px 0px 5px 0px rgba(60, 60, 60, 0.15);
        }
        & .input-group > * {
            height: 25px;
        }
        & button {
            height: 25px;
            --bs-btn-padding-x: 0.375rem;
            --bs-btn-padding-y: 0.25rem;
            display: flex;
            align-items: center;
            justify-content: center;
        }
        & input {
            padding: 0.25rem 0.75rem;
        }
        & .search-toggler {
            color: white;
            display: none;
        }
        & button[type='submit'] {
            --bs-btn-color: var(--bs-secondary-color);
            border: var(--bs-border-width) solid var(--bs-border-color);
            border-top-right-radius: 20px;
            border-bottom-right-radius: 20px;
        }
        & .search-icon {
            width: 15px;
            height: 15px;
            object-fit: contain;
            stroke: currentColor;
        }
        & .search-field {
            padding-right: 5px;
            border-top-left-radius: 20px;
            border-bottom-left-radius: 20px;
            &:focus {
                border-color: $green;
                outline: 0;
                box-shadow: unset;
            }
        }
        & input[type='search']::-webkit-search-cancel-button {
            -webkit-appearance: none;
            background: #fff;
        }
        & [type='search'] {
            --bs-focus-ring-color: $green-900;
            --bs-focus-ring-width: 0;
            font-size: 0.8125em;
            background: transparent;
            color: $gray-700;
            &::placeholder {
                //color: #fff;
                &:visited {
                    text-decoration: none;
                }
            }

            &._active {
                width: 98%;
                padding-left: 20px;
                transition: all 0.4s 0.7s ease;
            }
        }
        @include media-breakpoint-down(lg) {
            order: 4;
            grid-row: 2/3;
            grid-column: 8/12;
            align-content: end;
            justify-content: end;
            margin-right: 1rem;
        }
        @include media-breakpoint-down(md) {
            grid-row: 1/2;
            grid-column: 9/11;
            order: 0;
        }
        @include media-breakpoint-down(sm) {
            grid-column: 9/11;
            margin-right: 0;
        }
        @include media-breakpoint-between(xs, sm) {
            grid-column: 9/9;
        }
    }
    @include media-breakpoint-down(xl) {
        font-size: 0.75rem;
        & .header__menu {
            grid-column: span 11;
            order: 7;
            & #main__menu {
                width: 100%;
                margin: 0;
            }
            & .navbar-nav {
                width: 100%;
            }
        }
        & #search {
            position: static;
            grid-column: span 1;
            order: 6;
            justify-content: start;
            padding-left: 0;
            & #navbarSearchContent {
                visibility: hidden;
                opacity: 0;
                height: 0;
                &.show {
                    visibility: visible;
                    opacity: 1;
                    transition:
                        opacity 0.5s,
                        visibility 0.5s;
                    width: 100%;
                    height: 50px;
                    top: 10px;
                    background-color: #f7f8f9;
                    border-radius: 0;
                    display: flex;
                    align-items: center;
                    justify-content: stretch;
                    padding-left: 24px;
                    padding-right: 24px;
                }
                & input {
                    background-color: #fff;
                }
            }
            & .search-field,
            & .search-submit {
                //display: none !important;
            }
            & .search-toggler {
                --bs-navbar-toggler-padding-y: 5px;
                --bs-navbar-toggler-padding-x: 5px;
                --bs-navbar-color: white;
                --bs-navbar-toggler-border-color: $green;
                background-color: #009846;
                width: 24px;
                height: 24px;
                display: flex;
                border-radius: 3px;
                @include button-variant($green, $green, #fff, $green, $green, #fff, $green, $green, #fff, $gray-600, $gray-600, #fff);
            }
        }
        & .header__buttons {
            grid-column: span 6;
            & a.btn {
                --bs-btn-padding-y: 8px;
                --bs-btn-padding-x: 12px;
            }
        }
        & .header__phone {
            grid-column: span 3;
        }
    }
    @include media-breakpoint-down(lg) {
    }
}
body.scrolled .header {
    position: sticky;
    top: 0;
    left: 0;
    z-index: 10;
    box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.1);
    height: 118px;
    &__wrapper {
        height: unset;
    }
    & .header__inner {
        @include media-breakpoint-up(lg) {
            grid-template-rows: 78px 40px;
            & .header__logo {
                width: 280px;
                height: 74px;
                grid-row: span 1;
                padding: 5px;
            }
            & .header__info,
            & .header__socials {
                @include visually-hidden();
            }
            & .header__menu {
                grid-row: 2/3;
            }
        }
    }
}
[class~='__container'].container-fluid.container-xl {
    padding-right: 1.5rem;
    padding-left: 1.5rem;
    @include media-breakpoint-up(xl) {
        //padding-right: 0;
        //padding-left: 0;
    }
}
svg {
    fill: currentColor;
}
