.breadcrumbs {
    padding-top: 20px;
    padding-bottom: 20px;
}
.breadcrumb {
    display: inline-flex;
    flex-wrap: wrap;
    font-size: 0.75rem;
    font-weight: 400;
    line-height: normal;
    color: $gray-700;
    & > li {
        display: inline-block;
        padding-left: 5px;
        width: max-content;
        &::after {
            content: '/';
        }
        & > a {
            padding-right: 5px;
            color: inherit;
        }
        &.active {
            padding-left: 5px;
            color: #009846;
        }
    }
    & > li:first-child {
        padding-left: 0;
    }
    & > li:last-child {
        &::after {
            content: '';
        }
    }
}
