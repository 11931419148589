.price-block {
    select {
        max-width: 100%;
        overflow: hidden;
    }
    &__form {
        &::after,
        &::before {
            content: '';
            flex-basis: calc(100% / 12);
        }
        margin-bottom: 1.5rem;
        @include media-breakpoint-down(lg) {
            margin-bottom: 1.25rem;
        }
        & .form-items {
            flex-shrink: 0;
            & input::placeholder {
                color: #727272;
                font-size: 1rem;
                @include media-breakpoint-down(lg) {
                    font-size: 0.875rem;
                }
            }
            & .ss-main {
                padding: 0.375rem 0.5rem;
            }
            .ss-values {
                flex-wrap: wrap;
            }
            & .ss-placeholder {
                color: $gray-700;
            }
            border-radius: 10px;
            //padding: 20px 24px;
            align-items: center;
            display: flex;
            @include media-breakpoint-down(lg) {
                flex-direction: column;
                row-gap: 0.25rem;
            }
            column-gap: 1rem;
            justify-content: space-between;
            & > .form-item {
                box-sizing: border-box;
                @include media-breakpoint-only(lg) {
                    &:last-of-type {
                        display: none;
                    }
                }
                @include media-breakpoint-down(lg) {
                    flex-basis: 100%;
                    width: 100%;
                    max-width: unset;
                }
            }
            & [type='reset'] {
                width: 100%;
                @include media-breakpoint-only(lg) {
                    @include visually-hidden();
                    width: 0;
                }
                @include media-breakpoint-only(md) {
                    width: 50%;
                }
            }
        }
    }
    &__select,
    & #staticName {
        height: 100%;
        width: 100%;
    }
    &__select {
        @include font-size(1rem);
        @include media-breakpoint-down(lg) {
            font-size: 0.875rem;
        }
        & .ss-values {
            flex-wrap: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;
        }
    }
    background-color: $gray-200;
    & .price__table {
        width: 100%;
        background-color: transparent;
        border-collapse: collapse;
        @include media-breakpoint-down(lg) {
            & thead {
                display: none;
            }
        }
        & tbody > tr {
            background-color: transparent;
            overflow: hidden;
            @include media-breakpoint-down(lg) {
                display: block;
                border-bottom: 2px solid #bbb;
            }
            & .id {
                width: 5%;
            }
            & .name {
                width: 50%;
            }
            & .price {
                width: 15%;
                white-space: nowrap;
                text-align: end;
                font-weight: bold;
            }
            & .note {
                width: 30%;
            }
            & > td {
                background-color: #fff;
                border-radius: 5px;
                border-bottom: 4px solid $gray-200;
                padding: rfs-fluid-value(0.75rem) rfs-fluid-value(1.5rem);
                height: auto;
                white-space: normal;
                @include font-size(1rem);
                @include media-breakpoint-down(lg) {
                    width: 100% !important;
                    @include font-size(0.875rem);
                    display: flex;
                    justify-content: flex-start;
                    word-break: break-word;
                    word-wrap: break-word;
                    text-align: start;
                    &:nth-child(1) {
                        //display: block;
                        &::before {
                            //text-align: center;
                            height: 100%;
                        }
                    }
                    &::before {
                        display: inline-block;
                        min-width: 120px;
                        content: attr(data-label);
                        font-weight: bold;
                        margin-right: 10px;
                        text-align: start;
                    }
                }
                @include media-breakpoint-down(md) {
                    padding: rfs-fluid-value(0.625rem) rfs-fluid-value(1rem);
                }
                @include media-breakpoint-down(sm) {
                    @include font-size(0.75rem);
                }
                color: $gray-900;
                font-weight: 400;
                line-height: normal;
                /* & > strong,
                & > b {
                    font-weight: 700;
                } */
            }
        }
        & caption {
            @include visually-hidden();
        }
    }
}
