$maxWidthContainer: 1200;
$maxWidth: 1140;

//Подключение шрифта
@mixin font($font_name, $file_name, $weight, $style) {
    @font-face {
        font-family: $font_name;
        font-display: swap;
        src:
            url('../fonts/#{$file_name}.woff') format('woff'),
            url('../fonts/#{$file_name}.woff2') format('woff2');
        font-weight: #{$weight};
        font-style: #{$style};
    }
}

//Адаптивный шрифт
@mixin adaptiv-font($pcSize, $mobSize, $maxSize) {
    $addSize: $pcSize - $mobSize;
    @if $maxSize==1 {
        //Только если меньше контейнера
        font-size: $pcSize + px;
        @media (max-width: #{$maxWidthContainer + px}) {
            font-size: calc(#{$mobSize + px} + #{$addSize} * ((100vw - 320px) / #{$maxWidthContainer - 320}));
        }
    } @else if $maxSize==2 {
        //Только если больше контейнера
        font-size: $pcSize + px;
        @media (min-width: #{$maxWidthContainer + px}) {
            font-size: calc(#{$mobSize + px} + #{$addSize} * ((100vw - 320px) / #{$maxWidth - 320}));
        }
    } @else {
        //Всегда
        font-size: calc(#{$mobSize + px} + #{$addSize} * ((100vw - 320px) / #{$maxWidth - 320}));
    }
}

//Адаптивное свойство
@mixin adaptiv-value($property, $startSize, $minSize, $type) {
    $addSize: $startSize - $minSize;
    @if $type==1 {
        //Только если меньше контейнера
        #{$property}: $startSize + px;
        @media (max-width: #{$maxWidthContainer + px}) {
            #{$property}: calc(#{$minSize + px} + #{$addSize} * ((100vw - 320px) / #{$maxWidthContainer - 320}));
        }
    } @else if $type==2 {
        //Только если больше контейнера
        #{$property}: $startSize + px;
        @media (min-width: #{$maxWidthContainer + px}) {
            #{$property}: calc(#{$minSize + px} + #{$addSize} * ((100vw - 320px) / #{$maxWidth - 320}));
        }
    } @else {
        //Всегда
        #{$property}: calc(#{$minSize + px} + #{$addSize} * ((100vw - 320px) / #{$maxWidth - 320}));
    }
}

@mixin bg($size: 'contain', $position: 'center') {
    background-size: #{$size};
    background-position: #{$position};
    background-repeat: no-repeat;
}

@mixin btn_anim($scaleMax: 1.05, $scaleMin: 0.95) {
    transform-origin: center center;
    transition: all ease-out 240ms;

    &:hover {
        transform: scale(#{$scaleMax});
    }

    &:focus {
        outline: transparent;
    }

    &:focus-visible {
        transform: scale(#{$scaleMax}) translateY(-5%);
    }

    &:active {
        transform: scale(#{$scaleMin});
    }
}

@mixin no-btn($display: 'inline-block') {
    padding: 0;
    margin: 0;
    border: 0;
    background-color: transparent;
    border-radius: 0;
    cursor: pointer;
    appearance: none;
    display: #{$display};
}

@mixin transit($tr: 0.24) {
    transition: all #{$tr}s;
}

@mixin container($max-width: '120rem', $padding: '2rem') {
    width: 100%;
    max-width: #{$max-width};
    padding: 0 #{$padding};
    margin: 0 auto;
}
