.nav.nav-tabs {
    --bs-border-width: 0;
    border-radius: var(--bs-border-radius);
    overflow: hidden;
    background-color: #fff;
    border-bottom: 1px solid var(--bs-gray-100);
    font-size: 1rem;
    @include media-breakpoint-down(sm) {
        background-color: transparent;
        border-bottom: 1px solid transparent;
        //margin-bottom: 1em;
        box-shadow: 0px 0px 20px rgba(106, 106, 106, 0.15);
        margin-bottom: none;
    }
    & .nav-link {
        width: 240px;
        &.no-borders {
            width: auto;
        }
        border-top: 8px solid transparent;
        color: var(--bs-gray-700);
        background-color: transparent;
        padding: 0.5em;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        &:first-child {
            border-top-left-radius: var(--bs-border-radius) !important;
            border-top-right-radius: var(--bs-border-radius) !important;
        }
        & .pict {
            width: 40px;
            height: 40px;
            margin-bottom: 0.25em;
            & img,
            & svg {
                width: 100%;
                height: 100%;
                object-fit: contain;
                object-position: center center;
            }
        }
        span {
            font-size: inherit;
        }
        &.active {
            --bs-nav-tabs-link-active-border-color: transparent;
            color: black;
            border-top: 8px solid $green;
            box-shadow: 2px -2px 10px 0px rgba(0, 0, 0, 0.1);
        }
        &.active.no-borders {
            box-shadow: none;
            border-top: none;
        }
        @include media-breakpoint-down(xl) {
            //width: 130px;
            & .pict {
                width: 32px;
                height: 32px;
            }
            & span {
                font-size: 0.75em;
                &.active {
                    font-size: 0.875em;
                }
            }
        }
        @include media-breakpoint-down(lg) {
            //width: 150px;
        }
        @include media-breakpoint-down(md) {
            //width: 130px;
        }
        @include media-breakpoint-down(md) {
            font-size: 1.125em;
            //width: 110px;
        }
        @include media-breakpoint-down(sm) {
            transition:
                0.15s ease-in-out,
                background-color 0.15s ease-in-out;
            width: 100%;
            height: 40px;
            justify-content: flex-start;
            border-top: none !important;
            border-left: 8px solid transparent;
            flex-direction: row;
            flex-wrap: nowrap;
            --bs-border-bottom-right-radius: 7px;
            & .pict {
                flex: 0 0 24px;
                width: 24px;
                height: 24px;
                margin-right: 0.5em;
                margin-bottom: 0;
            }
            &.active {
                color: black;
                border-left: 8px solid $green;
                box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.25);
            }
        }
    }
}
.tab-content {
    //font-size: 1rem;
    padding: 2em 4em;
    @include media-breakpoint-down(xl) {
        padding: 1.75em 2em;
    }
    @include media-breakpoint-down(lg) {
        padding: 1.75em 1.5em;
    }
    @include media-breakpoint-down(md) {
        padding: 1em 1.125em;
    }
    @include media-breakpoint-down(sm) {
        padding: 0.75em 1.25em;
    }
    @include media-breakpoint-down(sm) {
        box-shadow: 0px 0px 20px rgba(106, 106, 106, 0.15);
    }
}
