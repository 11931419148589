$blue: #013082;
$green: rgba(0, 152, 70, 1); //#009846;
$green-100: #eff8f3;
$green-900: #025626;
$orange: #ffb800;
$yellow: #fff9c0;
$purple: #7794c7;
$purple-100: #c8d5fb;
// gray colors
$gray-100: #f0f0f0;
$gray-200: #f7f8f9;
$gray-300: #d9d9d9;
$gray-600: #727272;
$gray-800: #3c3c3c;
$gray-700: #4d4d4d;
$gray-900: #232323;

$min-contrast-ratio: 4.3;

$enable-shadows: false;

$enable-gradients: false;
$enable-negative-margins: true;
$border-radius: 8px;

$font-family-sans-serif: 'Open Sans';

$line-height-base: 1.5;
$line-height-sm: 1.25;
$line-height-lg: 2;

$font-size-base: 1rem;
$h1-font-size: $font-size-base * 2.5;
$h2-font-size: $font-size-base * 2;
$h3-font-size: $font-size-base * 1.5;
$h4-font-size: $font-size-base * 1.125;
$h5-font-size: $font-size-base * 1;
$h6-font-size: $font-size-base * 0.875;
$h7-font-size: $font-size-base * 0.5;

//buttons
$btn-hover-bg-shade-amount: 0%;
$btn-hover-border-shade-amount: 0%;
$btn-box-shadow: none;
$btn-active-box-shadow: none;

//btn-navbar-toggler

$navbar-toggler-padding-y: 0.125rem;
$navbar-toggler-padding-x: 0.5rem;
$navbar-toggler-font-size: 0.75rem;
$navbar-toggler-border-radius: 0.1875rem;
//$nav-link-font-size: 12px;
//$navbar-light-color: #fff;
//$navbar-light-icon-color: rgba($green, 1);

$navbar-light-toggler-icon-bg: url('data:image/svg+xml,<svg width="12" height="10" viewBox="0 0 12 10" fill="none" xmlns="http://www.w3.org/2000/svg"><line x1="0.75" y1="1.25" x2="11.25" y2="1.25" stroke="white" stroke-width="1.5" stroke-linecap="round"/><line x1="0.75" y1="5.25" x2="11.25" y2="5.25" stroke="white" stroke-width="1.5" stroke-linecap="round"/><line x1="0.75" y1="9.25" x2="11.25" y2="9.25" stroke="white" stroke-width="1.5" stroke-linecap="round"/></svg>');

//$navbar-toggler-focus-width: $btn-focus-width !default;
//$navbar-toggler-transition: box-shadow 0.15s ease-in-out !default;

// Links

$link-color: $gray-900;
//$link-decoration: ;
$link-hover-color: $blue;

/* // scss-docs-start grid-breakpoints
$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
  xxl: 1400px,
) !default;
// scss-docs-end grid-breakpoints */
$spacer: 1rem;
$spacers: (
    0: 0,
    1: $spacer * 0.25,
    2: $spacer * 0.5,
    3: $spacer,
    4: $spacer * 1.5,
    5: $spacer * 3,
    6: $spacer * 2,
    7: $spacer * 4
);
// scss-docs-start container-max-widths
$container-max-widths: (
    sm: 528px,
    md: 720px,
    lg: 960px,
    xl: 1140px,
    xxl: 1320px
);
// scss-docs-end container-max-widths

// scss-docs-start position-map
$position-values: (
    0: 0,
    25: 25%,
    50: 50%,
    75: 75%,
    100: 100%
);
// scss-docs-end position-map
//<svg width="12" height="8" viewBox="0 0 12 8" fill="none" xmlns="http://www.w3.org/2000/svg">
//<path d="M1 1L6 6L11 1" stroke="#4D4D4D" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round"/>
//</svg>

//Accordion
$accordion-icon-color: $gray-900;
$accordion-icon-active-color: $gray-900;
$accordion-button-icon: url("data:image/svg+xml,<svg width='15' height='14' viewBox='0 0 15 14' ill='none' xmlns='http://www.w3.org/2000/svg'><path d='M7.5 14L1.43782 3.5L13.5622 3.5L7.5 14Z' fill='#{$accordion-icon-color}'/></svg>");
$accordion-button-active-icon: url("data:image/svg+xml,<svg width='15' height='14' viewBox='0 0 15 14' ill='none' xmlns='http://www.w3.org/2000/svg'><path d='M7.5 14L1.43782 3.5L13.5622 3.5L7.5 14Z' fill='#{$accordion-icon-active-color}'/></svg>");

//close buttons
$focus-ring-box-shadow: none;

//forms
$form-check-input-checked-bg-color: #f0f0f0;
$form-check-input-border-radius: 50%;
$form-check-input-checked-bg-image: url('data:image/svg+xml,\
<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">\
<rect x="0.5" y="0.5" width="19" height="19" rx="7.5" fill="white" stroke="#F0F0F0"/>\
<rect x="4" y="4" width="12" height="12" rx="6" fill="#009846"/>\
</svg>');
$input-placeholder-color: $gray-600;
$input-plaintext-color: $gray-600;
$input-font-size: 0.875rem;
$input-color: $gray-600;
$input-border-color: #f0f0f0;
$form-label-color: $gray-600;

//modals
//$modal-inner-padding: 1rem 5rem;
$modal-header-border-width: 0;

//close button
$btn-close-color: $green;
$btn-close-bg: url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' width='43' height='44' viewBox='0 0 43 44' fill='none'%3e%3cpath d='M11.2662 33.3006L9.93998 31.9449C9.82133 31.7886 9.70465 31.6309 9.58375 31.4769C7.6054 28.9506 6.44217 26.0818 6.26882 22.848C5.97458 17.4062 7.92124 12.9366 12.1239 9.58729C15.2916 7.0632 18.9518 6.08974 22.9391 6.55281C26.9547 7.01961 30.2609 8.88939 32.8348 12.0669C34.7422 14.4181 35.8361 17.1421 36.1284 20.1814C36.4936 23.9709 35.6241 27.4495 33.492 30.5838C33.1753 31.0491 32.8244 31.4898 32.4892 31.942L31.1628 33.298C30.8713 33.5299 30.5835 33.7674 30.2882 33.9936C27.8655 35.8513 25.1424 36.8939 22.1198 37.0711C18.6511 37.2747 15.4742 36.3692 12.6157 34.3423C12.1528 34.0142 11.7153 33.6489 11.2662 33.3006ZM11.9245 31.261C17.029 36.4887 25.3511 36.4988 30.4848 31.2808C35.6185 26.0629 35.6241 17.5275 30.5072 12.2872C25.4019 7.05918 17.0778 7.05001 11.9472 12.2671C6.81664 17.4842 6.8099 26.0225 11.9245 31.261Z' fill='%23009846'/%3e%3cpath d='M21.216 20.4183L21.4659 20.1628C22.8886 18.7085 24.3114 17.2541 25.7343 15.7996C26.2672 15.2548 27.1014 15.4286 27.3216 16.1268C27.4518 16.5391 27.3267 16.8837 27.0296 17.1857C25.6239 18.6161 24.2215 20.0497 22.8221 21.4866L22.5416 21.7734L22.7857 22.0228C24.2084 23.4771 25.631 24.9313 27.0535 26.3855C27.5679 26.9113 27.4383 27.7337 26.7988 27.9923C26.3822 28.1612 26.0215 28.0453 25.7065 27.7216C24.2939 26.2711 22.8787 24.8235 21.4609 23.3788L21.216 23.1285L20.9765 23.3733C19.5538 24.8276 18.131 26.282 16.7081 27.7365C16.1903 28.2658 15.3861 28.1388 15.134 27.4871C14.9685 27.0633 15.0731 26.6937 15.3903 26.3694C16.8095 24.9252 18.2259 23.4783 19.6396 22.0286L19.889 21.7737L19.6539 21.5334C18.2234 20.0711 16.7928 18.6133 15.3657 17.1498C14.8434 16.6159 15.0069 15.7761 15.6739 15.5375C16.0764 15.3939 16.4175 15.5132 16.714 15.8162C18.1272 17.2662 19.5428 18.7133 20.961 20.1576L21.216 20.4183Z' fill='%23009846'/%3e%3c/svg%3e");
$btn-close-width: 2.5rem;
$btn-close-height: 2.5rem;
$btn-close-padding-y: 0.25rem;
$btn-close-padding-x: 0.25rem;
