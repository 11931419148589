.partners__block {
    background-color: #f7f8f9;
    padding: 2rem auto;
    & .partners__block-inner {
        @include media-breakpoint-up(xl) {
            padding-left: 45px;
            padding-right: 45px;
        }
    }
    & .partners-slider {
        @include media-breakpoint-up(xl) {
            position: relative;
            overflow-x: clip;
            overflow-clip-margin: 45px;
        }
    }
    @include media-breakpoint-up(xl) {
        & .partners-slider__arrows {
            position: absolute;
            top: 50%;
            transform: translateY(50%);

            &.slider__arrow-prev {
                left: -40px;
            }
            &.slider__arrow-next {
                right: -40px;
            }
        }
    }

    & .partners-slider__controls {
        margin-top: 1em;
        margin-bottom: 2em;
        @include media-breakpoint-up(lg) {
            margin-top: 1.5em;
        }
        @include media-breakpoint-up(xl) {
            position: absolute;
            width: auto;
            height: 40px;
            top: 5px;
            left: -45px;
            right: -45px;
            margin-top: 0;
            margin-bottom: 0;
            transform: translateY(50%);
            display: flex;
            justify-content: space-between;
        }
        & .partners-slider__dots {
            @include media-breakpoint-up(xl) {
                @include visually-hidden;
            }
        }
    }
    & .partners__wrapper {
        margin-bottom: 1rem;
        & .partners__slide {
            height: 90px !important;
            background-color: #fff;
            border-radius: 7px;
            border: 1px solid var(--grey-stroke, #f0f0f0);
            width: auto;
            @include media-breakpoint-down(md) {
                height: 80px !important;
            }
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 5px;
            overflow: hidden;
            & > img {
                max-height: 80px;
                @include media-breakpoint-down(md) {
                    height: 70px;
                }
                object-fit: contain;
                width: 100%;
                //object-fit: ;
                //object-position: center;
                //height: 100%;
                //width: inherit;
            }
        }
    }
}
