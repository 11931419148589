.personal-services {
    background-color: $gray-200;
    &__wrapper {
    }
    &__list {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        @include media-breakpoint-down(md) {
            grid-template-columns: repeat(1, 1fr);
        }
        grid-template-rows: auto;
        column-gap: 1.5rem;
        row-gap: 1rem;
        & .item {
            background-color: #fff;
            border: 1px solid $gray-100;
            border-radius: 7px;
            /* @include padding-top(2rem);
            @include padding-bottom(2rem);
            @include padding-left(4rem);
            @include padding-right(4rem); */
            padding: rfs-fluid-value(2rem) rfs-fluid-value(4rem);
        }
        & .item__title {
            color: $green !important;
            font-weight: 600;
            line-height: normal;
            @include font-size(1.25rem);
            margin-bottom: rfs-fluid-value(1.5rem);
        }
        & .item__content {
            & > li {
                @include margin-bottom(0.625rem);
                white-space: normal;
                width: 100%;
                //margin-bottom: 0.625rem;
            }
            & li > a {
                width: 100%;
                text-align: start;
                line-height: normal;
                font-weight: 400;
                //@include font-size(1rem);
                font-size: inherit;
                &.disabled {
                    color: $gray-900;
                }
            }
            & li.marker > a {
                &:hover {
                    color: $green;
                    font-weight: 700;
                }
            }
        }
    }
}
