.page__banner2 {
    font-size: 1rem;
    .banner2__wrapper {
        height: 190px;
        @include media-breakpoint-down(xl) {
        }
        @include media-breakpoint-down(lg) {
            height: 180px;
        }
        @include media-breakpoint-down(md) {
            height: 170px;
        }
        @include media-breakpoint-down(sm) {
            height: 165px;
        }
        width: 100%;
        border-radius: 8px;
        overflow: hidden;
        position: relative;
        & .banner2-image {
            margin-left: 2rem;
            @include media-breakpoint-down(xl) {
            }
            @include media-breakpoint-down(lg) {
                margin-left: 1em;
            }
            @include media-breakpoint-down(md) {
                margin-left: 0;
            }
            @include media-breakpoint-down(sm) {
                margin-left: -2em;
            }
        }
        & .banner2-text {
            position: absolute;
            top: 0;
            left: 50%;
            right: 0;
            bottom: 0;
            display: flex;
            padding-right: 20px;
            flex-direction: column;
            justify-content: center;
            align-items: flex-start;
            @include media-breakpoint-down(md) {
                //align-items: center;
                padding-left: 24px;
            }
            @include media-breakpoint-down(sm) {
                align-items: flex-end;
            }
            & span {
                font-size: 2em;
                color: $blue;
                font-weight: 600;
                display: block;
                padding-bottom: 10px;
                @include media-breakpoint-down(xl) {
                    font-size: 1.5em;
                }
                @include media-breakpoint-down(lg) {
                    font-size: 1.25em;
                }
                @include media-breakpoint-down(md) {
                    font-size: 1em;
                }
                @include media-breakpoint-down(sm) {
                    width: min-content;
                    text-align: right;
                }
            }
            & .banner__action {
                width: 100%;
                display: flex;
                @include media-breakpoint-up(lg) {
                    align-items: center;
                }
                @include media-breakpoint-down(md) {
                    flex-direction: column;
                    padding-right: 10px;
                    justify-content: space-between;
                }
                @include media-breakpoint-down(sm) {
                    padding-right: 0;
                }
                align-items: inherit;
                justify-content: space-around;
                flex-grow: 0;
                flex-shrink: 1;
                & .btn {
                    --bs-btn-padding-y: 0.5em;
                    --bs-btn-padding-x: 2em;
                    margin-right: 1em;
                    margin-bottom: 0;
                    @include media-breakpoint-down(lg) {
                        margin-bottom: 0.5em;
                        font-size: 0.875em;
                    }
                    @include media-breakpoint-down(md) {
                        font-size: 0.75em;
                        --bs-btn-padding-x: 1.25em;
                    }
                    @include media-breakpoint-down(sm) {
                        margin-right: 0;
                        margin-bottom: 0;
                        font-size: 0.625em;
                    }
                }
                & .banner2-phone {
                    width: 100%;
                    text-align: left;
                    font-size: 1em;
                    font-weight: 600;
                    line-height: 1.75em;
                    @include media-breakpoint-down(xl) {
                        font-size: 0.875em;
                    }
                    @include media-breakpoint-down(lg) {
                    }
                    @include media-breakpoint-down(md) {
                        flex-grow: 1;
                        flex-shrink: 1;
                        width: unset;
                    }
                    @include media-breakpoint-down(sm) {
                        font-size: 0.75em;
                        text-align: right;
                    }
                    & .phone {
                        color: $green;
                        font-size: 2em;
                        display: block;
                        width: 100%;
                        font-weight: 600;
                        @include media-breakpoint-down(lg) {
                            font-size: 1.5em;
                        }
                        @include media-breakpoint-down(sm) {
                            font-size: 1.25em;
                        }
                    }
                }
            }
        }
        @include media-breakpoint-down(lg) {
        }
        @include media-breakpoint-down(md) {
        }
        @include media-breakpoint-down(sm) {
        }
    }
}

img:not([src]):not([srcset]) {
    visibility: hidden;
}
