/* section .section__block-caption {
    font-size: 1rem;
    display: flex;
    flex-wrap: nowrap;
    justify-content: stretch;
    align-items: center;
    gap: 2em;
    & span {
        font-size: 2em;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        @include media-breakpoint-down(xl) {
            font-size: 1.5em;
        }
        @include media-breakpoint-down(md) {
            font-size: 1.125em;
        }
    }
    @include media-breakpoint-down(xl) {
        gap: 1em;
    }
    &-caption {
        display: flex;
        flex-wrap: nowrap;
        justify-content: stretch;
        align-items: center;
        gap: 2em;
        @include media-breakpoint-down(xl) {
            gap: 1em;
        }
        @include media-breakpoint-down(lg) {
        }
    }
    & > a[type='button'] {
        flex: 0 0 max-content;
        margin-right: 2em;
        @include media-breakpoint-down(xl) {
            margin-right: 1.5em;
        }
        @include media-breakpoint-down(lg) {
            margin-right: 1em;
        }
        @include media-breakpoint-down(md) {
            margin-right: 0.5em;
        }
        @include media-breakpoint-down(sm) {
            margin-right: 0;
        }
        &:last-child {
            margin-right: 0;
        }
    }
}
 */
.filters__form {
    &::after,
    &::before {
        content: '';
        flex-basis: calc(100% / 12);
    }
    margin-bottom: 1.5rem;
    @include media-breakpoint-down(lg) {
        margin-bottom: 1.25rem;
    }
    & .form-items {
        flex-shrink: 0;
        & input::placeholder {
            color: #727272;
            font-size: 1rem;
            @include media-breakpoint-down(lg) {
                font-size: 0.875rem;
            }
        }
        border-radius: 10px;
        padding: 20px 24px;
        background: #eff8f3;
        align-items: center;
        display: flex;
        @include media-breakpoint-down(lg) {
            flex-direction: column;
            row-gap: 0.25rem;
        }
        column-gap: 1rem;
        justify-content: space-between;
        & > .form-item {
            flex-basis: calc(100% / 5);
            max-width: 20%;
            box-sizing: border-box;
            @include media-breakpoint-only(lg) {
                flex-basis: calc(100% / 4);
                max-width: 25%;
                &:last-of-type {
                    display: none;
                }
            }
            @include media-breakpoint-down(lg) {
                flex-basis: 100%;
                width: 100%;
                max-width: unset;
            }
        }
        & [type='reset'] {
            width: 100%;
            @include media-breakpoint-only(lg) {
                @include visually-hidden();
                width: 0;
            }
            @include media-breakpoint-only(md) {
                width: 50%;
            }
        }
    }
}
